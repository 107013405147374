import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Globals } from '../../../_shared/classes/globals'

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent extends Globals implements OnInit {
  loading: true
  settings: 'profile' | 'region' | 'topic'

  constructor(private route: ActivatedRoute) { super() }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.settings = data.settings
    })
  }
}
