<div class="hero">
  <h3>{{ translation.account.regions }}</h3>
</div>

<app-button class="float-right mr-2" (click)="openModal()" [disabled]="!countries"><mat-icon>add</mat-icon> {{ translation.regions.addRegion }}</app-button>

<table mat-table #table *ngIf="userRegions" [dataSource]="userRegions" class="table-full">

  <!-- Full name Column -->
  <ng-container matColumnDef="fullName">
    <th mat-header-cell *matHeaderCellDef>{{ translation.regions.region }}</th>
    <td mat-cell *matCellDef="let region">{{ region.fullName }}</td>
  </ng-container>

  <!-- Show regional news Column -->
  <ng-container matColumnDef="showRegionalNews">
    <th mat-header-cell *matHeaderCellDef>{{ translation.regions.showRegionalNews }}</th>
    <td mat-cell *matCellDef="let region">
      <mat-slide-toggle
        [checked]="region.showRegionalNews || region.hasCity"
        [disabled]="region.hasCity"
        (change)="onShowRegionalNews($event, region)">
      </mat-slide-toggle>
    </td>
  </ng-container>

  <!-- News count Column -->
  <ng-container matColumnDef="newsCount">
    <th mat-header-cell *matHeaderCellDef>{{ translation.regions.newsCount }}</th>
    <td mat-cell *matCellDef="let region">{{ region.newsCount }}</td>
  </ng-container>

  <!-- Delete Column -->
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let region">
      <button mat-icon-button (click)="onDeleteRegion(region)" color="accent">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div *ngIf="isLoading" class="mini-loader text-center my-5">
  <img src="../../../../../../assets/images/asterisk2.png" />
</div>
