interface IUserTopic {
  id: number | null
  fullName: string | null
  category_id: number | null
  subcategory_id?: number | number[] | null
  user_regionalization_id?: number | null
  region?: { fullName: string }
  newsCount?: number
}

export class UserTopic {
  id: number
  fullName: string
  category_id: number
  subcategory_id?: number | number[]
  user_regionalization_id?: number
  region?: { fullName: string }
  newsCount?: number

  constructor(userTopic: IUserTopic) {
    Object.assign(this, userTopic)
  }

  static empty(): UserTopic {
    return new UserTopic({
      id: null,
      fullName: null,
      category_id: null,
      subcategory_id: null,
      user_regionalization_id: null
    })
  }
}
