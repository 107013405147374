import { ApiClient } from '../api-client/ApiClient';
import { environment } from '../../../../environments/environment';
import { Country } from '../../models/country';
import { map } from 'rxjs/operators';
import { State } from '../../models/state';
import { HttpParams } from '@angular/common/http';
import { City } from '../../models/city';
import * as i0 from "@angular/core";
import * as i1 from "../api-client/ApiClient";
var RegionService = /** @class */ (function () {
    function RegionService(apiClient) {
        this.apiClient = apiClient;
        this.url = environment.apiEndpoint + "region/";
    }
    RegionService.prototype.getCountries = function () {
        return this.apiClient.get(this.url + "list-country").pipe(map(function (countries) { return countries.map(function (country) { return new Country(country); }); }));
    };
    RegionService.prototype.getStates = function (countryId) {
        var params = new HttpParams();
        params = params.append('country_id', countryId.toString());
        return this.apiClient.get(this.url + "list-state", { params: params }).pipe(map(function (states) { return states.map(function (state) { return new State(state); }); }));
    };
    RegionService.prototype.getCities = function (stateId) {
        var params = new HttpParams();
        params = params.append('state_id', stateId.toString());
        return this.apiClient.get(this.url + "list-city", { params: params }).pipe(map(function (cities) { return cities.map(function (city) { return new City(city); }); }));
    };
    RegionService.ngInjectableDef = i0.defineInjectable({ factory: function RegionService_Factory() { return new RegionService(i0.inject(i1.ApiClient)); }, token: RegionService, providedIn: "root" });
    return RegionService;
}());
export { RegionService };
