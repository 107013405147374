import { Editor } from './editor'
import { Source } from './source'
import { Subcategory } from './subcategory'

interface INews {
  id: number
  preheading: string
  heading: string
  subheading: string
  summary: string
  body: string
  imageUrl: string
  image_width: number
  image_height: number
  image_file: string
  dateISO: Date
  categoryName: string
  url: string
  editor_id: number
  source_id: number
  viewCount: number
  shareCount: number
  editor: Editor
  source: Source
  newsSubcategories: Subcategory[]
  seen: boolean
}

export class News {
  id: number
  preheading: string
  heading: string
  subheading: string
  summary: string
  body: string
  imageUrl: string
  image_width: number
  image_height: number
  image_file: string
  dateISO: Date
  categoryName: string
  url: string
  editor_id: number
  source_id: number
  viewCount: number
  shareCount: number
  editor: Editor
  source: Source
  newsSubcategories: Subcategory[]
  seen: boolean

  constructor(news: INews) {
    Object.assign(this, news)

    if (this.dateISO) {
      this.dateISO = new Date(this.dateISO)
    }

    if (this.editor) {
      this.editor = new Editor(this.editor)
    }

    if (this.source) {
      this.source = new Source(this.source)
    }

    if (news.seen === null || news.seen === undefined) {
      news.seen = true
      this.seen = true
    }
  }

  get hasBigImage(): boolean {
    return !!this.imageUrl && this.image_width >= 400 && this.image_height >= 200
  }
}
