import { Country } from './country'

interface IState {
  id: number
  name: string
  country: Country
}

export class State {
  id: number
  name: string
  country: Country

  constructor(state: IState) {
    Object.assign(this, state)
  }
}
