import { NgModule } from '@angular/core'
import { RouterModule, Routes, PreloadAllModules } from '@angular/router'
import { AuthGuard } from './auth/auth-guard.service'
import { FrameworkGuard } from './framework/framework-guard.service'

const routes: Routes = [
  {
    path: '',
    loadChildren: './framework/framework.module#FrameworkModule',
    canActivate: [AuthGuard, FrameworkGuard]
  },
  {
    path: '',
    loadChildren: './auth/auth.module#AuthModule'
  }
  /*,
  {
    path: '',
    redirectTo: '/auth',
    pathMatch: 'full'
  }*/
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      /*enableTracing: true,*/
      useHash: false,
      preloadingStrategy: PreloadAllModules
    })
  ],
  providers: [FrameworkGuard]
})
export class AppRoutingModule {}
