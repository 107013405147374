import { Component, OnInit, ViewChild } from '@angular/core'
import { Globals } from '../../../../../_shared/classes/globals'
import { UserService } from '../../../../../_shared/services/user-service/user.service'
import { UserRegion } from '../../../../../_shared/models/user-region'
import { MatDialog, MatSlideToggleChange, MatTable } from '@angular/material'
import { AccountRegionModalComponent } from '../account-region-modal/account-region-modal.component'
import { RegionService } from '../../../../../_shared/services/region-service/region.service'
import { Country } from '../../../../../_shared/models/country'
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-account-region',
  templateUrl: './account-region.component.html',
  styleUrls: ['./account-region.component.scss']
})
export class AccountRegionComponent extends Globals implements OnInit {
  @ViewChild('table') table: MatTable<UserRegion>

  userRegions: UserRegion[]
  countries: Country[]
  isLoading = true
  displayedColumns = ['fullName', 'showRegionalNews', 'newsCount', 'delete']

  constructor(
    private userService: UserService,
    private regionService: RegionService,
    private dialog: MatDialog) { super() }

  ngOnInit() {
    this.getData()
    this.getCountries()
  }

  private getData(): void {
    this.userService.getUserRegions().subscribe(userRegions => {
      this.isLoading = false
      this.userRegions = userRegions

      if (this.table) {
        this.table.renderRows()
      }
    })
  }

  onShowRegionalNews(toggle: MatSlideToggleChange, region: UserRegion): void {
    region.show_regional_news = toggle.checked ? 1 : 0

    this.userService.updateUserRegion(region).subscribe({
      error: () => region.show_regional_news === 1 ? 0 : 1
    })
  }

  onDeleteRegion(deletedRegion: UserRegion): void {
    const backup = [...this.userRegions]
    this.userRegions = this.userRegions.filter(region => region.id !== deletedRegion.id)

    this.userService.deleteUserRegion(deletedRegion).subscribe({
      error: () => this.userRegions = backup
    })
  }

  openModal(): void {
    const dialogRef = this.dialog.open(AccountRegionModalComponent, {
      width: '400px',
      data: { countries: this.countries }
    })

    dialogRef.afterClosed()
      .pipe(
        filter(newUserRegion => !!newUserRegion && newUserRegion.country_id)
      )
      .subscribe(newUserRegion => {
        this.userService.sendUserRegion(newUserRegion).subscribe(() => {
          this.getData()
        })
      })
  }

  private getCountries(): void {
    this.regionService.getCountries().subscribe(countries => {
      this.countries = countries
    })
  }
}
