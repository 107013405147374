var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Globals } from '../../../../../_shared/classes/globals';
import { UserService } from '../../../../../_shared/services/user-service/user.service';
import { MatDialog, MatTable } from '@angular/material';
import { UserRegion } from '../../../../../_shared/models/user-region';
import { TopicService } from '../../../../../_shared/services/topic-service/topic.service';
import { filter } from 'rxjs/operators';
import { AccountTopicModalComponent } from '../account-topic-modal/account-topic-modal.component';
var AccountTopicComponent = /** @class */ (function (_super) {
    __extends(AccountTopicComponent, _super);
    function AccountTopicComponent(userService, topicService, dialog) {
        var _this = _super.call(this) || this;
        _this.userService = userService;
        _this.topicService = topicService;
        _this.dialog = dialog;
        _this.isLoading = true;
        _this.displayedColumns = ['fullName', 'newsCount', 'delete'];
        return _this;
    }
    AccountTopicComponent.prototype.ngOnInit = function () {
        this.getData();
        this.getCategories();
        this.getUserRegions();
    };
    AccountTopicComponent.prototype.getData = function () {
        var _this = this;
        this.userService.getUserTopics().subscribe(function (userTopics) {
            _this.isLoading = false;
            _this.userTopics = userTopics;
            if (_this.table) {
                _this.table.renderRows();
            }
        });
    };
    AccountTopicComponent.prototype.getCategories = function () {
        var _this = this;
        this.topicService.getCategories().subscribe(function (categories) {
            _this.categories = categories;
        });
    };
    AccountTopicComponent.prototype.getUserRegions = function () {
        var _this = this;
        this.topicService.getUserRegions().subscribe(function (regions) {
            _this.userRegions = regions;
        });
    };
    AccountTopicComponent.prototype.onDeleteTopic = function (deletedTopic) {
        var _this = this;
        var backup = this.userTopics.slice();
        this.userTopics = this.userTopics.filter(function (topic) { return topic.id !== deletedTopic.id; });
        this.userService.deleteUserTopic(deletedTopic).subscribe({
            error: function () { return _this.userTopics = backup; }
        });
    };
    AccountTopicComponent.prototype.openModal = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AccountTopicModalComponent, {
            width: '400px',
            data: { categories: this.categories, userRegions: this.userRegions }
        });
        dialogRef.afterClosed()
            .pipe(filter(function (newUserTopic) { return !!newUserTopic && newUserTopic.category_id; }))
            .subscribe(function (newUserTopic) {
            _this.userService.sendUserTopic(newUserTopic).subscribe(function () {
                _this.getData();
            });
        });
    };
    Object.defineProperty(AccountTopicComponent.prototype, "userRegionsUI", {
        get: function () {
            var emptyRegion = UserRegion.empty();
            return [emptyRegion].concat(this.userRegions);
        },
        enumerable: true,
        configurable: true
    });
    return AccountTopicComponent;
}(Globals));
export { AccountTopicComponent };
