var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../../../../_shared/services/user-service/user.service';
import { Globals } from '../../../../../_shared/classes/globals';
var AccountProfileComponent = /** @class */ (function (_super) {
    __extends(AccountProfileComponent, _super);
    function AccountProfileComponent(formBuilder, userService) {
        var _this = _super.call(this) || this;
        _this.formBuilder = formBuilder;
        _this.userService = userService;
        _this.isLoading = true;
        _this.isSaving = false;
        return _this;
    }
    AccountProfileComponent.prototype.ngOnInit = function () {
        this.getData();
    };
    AccountProfileComponent.prototype.buildForm = function () {
        this.profileForm = this.formBuilder.group({
            username: [this.user.username, Validators.required],
            name: [this.user.name, Validators.required],
            email: [this.user.email, Validators.compose([Validators.email, Validators.required])]
        });
    };
    AccountProfileComponent.prototype.getData = function () {
        var _this = this;
        this.userService.getUser().subscribe(function (user) {
            _this.isLoading = false;
            _this.user = user;
            _this.buildForm();
        });
    };
    AccountProfileComponent.prototype.getErrorMessage = function (field) {
        var control = this.profileForm.get(field);
        if (control) {
            return control.hasError('required') ? this.translation.validations.required :
                control.hasError('email') ? this.translation.validations.email : null;
        }
        return null;
    };
    AccountProfileComponent.prototype.save = function () {
        var _this = this;
        if (this.profileForm.valid) {
            this.isSaving = true;
            var usernameField = this.profileForm.get('username');
            var nameField = this.profileForm.get('name');
            var emailField = this.profileForm.get('email');
            if (usernameField && nameField && emailField) {
                this.user.username = usernameField.value;
                this.user.name = nameField.value;
                this.user.email = emailField.value;
                this.userService.updateProfile(this.user).subscribe({
                    complete: function () { return _this.isSaving = false; }
                });
            }
        }
    };
    return AccountProfileComponent;
}(Globals));
export { AccountProfileComponent };
