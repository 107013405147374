import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { NewsService } from '../../../_shared/services/news-service/news.service'
import { News } from '../../../_shared/models/news'
import { Subscription, fromEvent } from 'rxjs'
import { debounceTime, filter } from 'rxjs/operators'
import { NewsComponent } from '../../components/news/news.component'
import { Globals } from '../../../_shared/classes/globals'

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent extends Globals implements OnInit, OnDestroy  {
  @ViewChild('newsList', {read: ElementRef}) newsList: ElementRef<HTMLDivElement>
  @ViewChildren(NewsComponent) newsComponents: QueryList<NewsComponent>

  news: News[]
  loading = false

  subscriptions: Subscription[] = []

  constructor(private newsService: NewsService) { super() }

  ngOnInit() {
    this.getNews()
    this.checkNewsVisibility()
  }

  ngOnDestroy() {
    this.subscriptions.filter(s => !s.closed).forEach(s => s.unsubscribe())
    this.newsService.pollingCancelled.next(true)
  }

  getNews(): void {
    this.loading = true

    this.subscriptions.push(
      this.newsService.getNews().subscribe(news => {
        this.news = news
        this.loading = false
        this.onScroll()
        this.pollNews()
      })
    )
  }

  onScroll(): void {
    /*of(event).pipe(
      filter(index => index > this.news.length - 20 && !this.loading)
    ).subscribe(() => {
      this.loading = true
      this.newsService.getNews(this.news[this.news.length - 1].id).subscribe(news => {
        this.news.push(...news)
        this.observableData.next(news)
        this.loading = false
      })
    })
  }*/

    this.subscriptions.push(
      fromEvent(document, 'scroll').pipe(
        debounceTime(300),
        filter(() => !this.loading)
      ).subscribe((event: Event) => {
        if (event.target) {
          const target = event.target as HTMLDocument

          if (target.scrollingElement) {
            const element = target.scrollingElement as HTMLElement
            const height = element.scrollHeight
            const pos = element.scrollTop + element.offsetHeight

            if (pos >= height - 2000) {
              this.newsService.getMoreNews(this.news[this.news.length - 1].id).subscribe(news => {
                this.news.push(...news)
              })
            }
          }
        }
      })
    )
  }

  pollNews(): void {
    this.subscriptions.push(
      this.newsService.pollNews().subscribe(news => {

        // Prevent auto scroll to the top after prepend
        if (document.scrollingElement && document.scrollingElement.scrollTop === 0) {
          document.scrollingElement.scrollTo(0, 1)
        }

        this.news.unshift(...news)
      })
    )
  }

  checkNewsVisibility(): void {
    fromEvent(document, 'scroll')
      .pipe(debounceTime(300))
      .subscribe((event: Event) => {
        if (document.scrollingElement && document.scrollingElement.scrollTop < 25) {
          this.newsService.newsSeenAll.next()
        } else {
          this.newsComponents.filter(component => !component.news.seen).forEach(component => {
            if (component.isVisibleInScroll(document.scrollingElement as HTMLElement)) {
              this.newsService.newsSeen.next()
            }
          })
        }
      })
  }
}
