<div *ngIf="size === 'normal'" class="news-wrapper mb-4 normal">
  <div class="first-row d-flex mb-2">
    <app-editor-avatar class="pl-3" [editor]="news.editor"></app-editor-avatar>

    <div class="date-wrapper text-right">
      <span class="date">{{ news.dateISO | timeAgo }}</span>

      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vertical</mat-icon>
      </button>

      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item>
          <mat-icon>share</mat-icon>
          <span>{{ translation.common.share }}</span>
        </button>
        <button mat-menu-item *ngIf="true">
          <mat-icon>star</mat-icon>
          <span>{{ translation.editor.followEditor }}</span>
        </button>
        <button mat-menu-item *ngIf="false">
          <mat-icon>star_border</mat-icon>
          <span>{{ translation.editor.unfollowEditor }}</span>
        </button>
        <button mat-menu-item>
          <mat-icon>block</mat-icon>
          <span>{{ translation.editor.blockEditor }}</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <a
    *ngIf="news.hasBigImage"
    class="image-big"
    [style.backgroundImage]="'url('+ news.imageUrl +')'"
    [href]="news.url"
    target="_blank">
    <div class="stats p-3">
      <span class="mr-2"><mat-icon class="align-middle">visibility</mat-icon> {{ news.viewCount }}</span>
      <span><mat-icon class="align-middle">share</mat-icon> {{ news.shareCount }}</span>
    </div>
    <span class="category h5 m-0">{{ news.categoryName }}</span>
  </a>

  <div class="info p-3">
    <a
      *ngIf="news.imageUrl && !news.hasBigImage"
      class="image-small text-center mb-3"
      [href]="news.url"
      target="_blank">
      <img [src]="news.imageUrl">
    </a>
    <div>
      <div *ngIf="!news.hasBigImage" class="mb-2">
        <span class="category">{{ news.categoryName }}</span>

        <div class="stats ml-2 d-inline float-right">
          <span class="mr-2"><mat-icon class="align-middle">visibility</mat-icon> {{ news.viewCount }}</span>
          <span><mat-icon class="align-middle">share</mat-icon> {{ news.shareCount }}</span>
        </div>
      </div>

      <h2><a [href]="news.url" target="_blank">{{ news.heading }}</a></h2>
      <p *ngIf="!news.hasBigImage">{{ news.summary }}</p>
    </div>

  </div>
</div>

<div *ngIf="size === 'small'" class="news-wrapper small">
  <div class="first-row d-flex mb-2">
    <span class="category mr-1">{{ news.categoryName }}</span>
    <span><mat-icon class="align-middle">visibility</mat-icon> {{ news.viewCount }}</span>
    <div class="date-wrapper text-right">
      <span class="date">{{ news.dateISO | timeAgo }}</span>
    </div>
  </div>

  <div class="info">
    <h6><a [href]="news.url" target="_blank">{{ news.heading }}</a></h6>
  </div>

  <app-editor-avatar [editor]="news.editor" size="small"></app-editor-avatar>
</div>
