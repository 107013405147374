/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-section.component";
var styles_FormSectionComponent = [i0.styles];
var RenderType_FormSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormSectionComponent, data: {} });
export { RenderType_FormSectionComponent as RenderType_FormSectionComponent };
function View_FormSectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mb-4 py-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormSectionComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FormSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-section", [], null, null, null, View_FormSectionComponent_0, RenderType_FormSectionComponent)), i1.ɵdid(1, 114688, null, 0, i3.FormSectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormSectionComponentNgFactory = i1.ɵccf("app-form-section", i3.FormSectionComponent, View_FormSectionComponent_Host_0, { title: "title" }, {}, ["*"]);
export { FormSectionComponentNgFactory as FormSectionComponentNgFactory };
