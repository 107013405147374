import { Injectable } from '@angular/core'
import { ApiClient } from '../api-client/ApiClient'
import { environment } from '../../../../environments/environment'
import { Observable } from 'rxjs'
import { Country } from '../../models/country'
import { map } from 'rxjs/operators'
import { State } from '../../models/state'
import { HttpParams } from '@angular/common/http'
import { City } from '../../models/city'

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  private readonly url: string

  constructor(private apiClient: ApiClient) {
    this.url = `${environment.apiEndpoint}region/`
  }

  getCountries(): Observable<Country[]> {
    return this.apiClient.get<Country[]>(`${this.url}list-country`).pipe(
      map(countries => countries.map(country => new Country(country)))
    )
  }

  getStates(countryId: number): Observable<State[]> {
    let params = new HttpParams()
    params = params.append('country_id', countryId.toString())

    return this.apiClient.get<State[]>(`${this.url}list-state`, { params }).pipe(
      map(states => states.map(state => new State(state)))
    )
  }

  getCities(stateId: number): Observable<City[]> {
    let params = new HttpParams()
    params = params.append('state_id', stateId.toString())

    return this.apiClient.get<City[]>(`${this.url}list-city`, { params }).pipe(
      map(cities => cities.map(city => new City(city)))
    )
  }
}
