import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() type: 'button' | 'submit' = 'button'
  @Input() color = 'accent'
  @Input() isLoading = false
  @Input() disabled = false

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>()

  constructor() { }

  ngOnInit() {
  }

  onClick(): void {
    if (!this.isLoading) {
      this.clicked.emit()
    }
  }
}
