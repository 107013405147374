import getLanguage from '../functions/get-language'

export type Language = 'en-US' | 'es-US'

const translations = {
  'en-US': {
    common: {
      login: 'Login',
      logout: 'Logout',
      register: 'Register',
      welcome: 'Welcome, {{name}}',
      share: 'Share',
      userAccount: 'User account',
      save: 'Save',
      cancel: 'Cancel',
      send: 'Send',
      all: 'All'
    },
    validations: {
      required: 'You must enter a value',
      email: 'You must enter a valid email'
    },
    app: {
      whatIs: 'What is',
      editors: 'Editors',
      privacyPolicy: 'Privacy Policies',
      termsOfService: 'Terms of Service'
    },
    loginForm: {
      username: 'Username',
      password: 'Password',
      enterUsername: 'Enter your Username',
      enterPassword: 'Enter your Password',
      enterCredentials: 'Enter your credentials'
    },
    signupForm: {
      business: 'Brand or business',
      phone: 'Phone',
      email: 'Email',
      web: 'Website',
      country: 'Country',
      category: 'Category'
    },
    home: {
      motto: 'The news you want from where you care'
    },
    timeline: {
      publishTitle: 'Be part of Sqoops',
      publishDesc: 'If you are an Editor and want thousands of views of your news, register now.'
    },
    editor: {
      mostViewed: 'The most viewed',
      todaySqoops: `Today's Sqoops`,
      todayNews: `Today's News`,
      followers: 'Followers',
      followEditor: 'Follow Editor',
      unfollowEditor: 'Unfollow Editor',
      blockEditor: 'Block Editor'
    },
    account: {
      profile: 'Profile',
      regions: 'Regions',
      topics: 'Topics',
      languages: 'Languages',
      preferences: 'Preferences',
      blockedEditors: 'Blocked editors',
      favoriteEditors: 'Favorite editors',
      savedArticles: 'Saved articles'
    },
    profile: {
      username: 'Username',
      name: 'Name',
      email: 'Email',
      enterUsername: 'Enter your Username',
      enterName: 'Enter your Name',
      enterEmail: 'Enter your Email'
    },
    regions: {
      region: 'Region',
      showRegionalNews: 'Show regional news',
      newsCount: 'News count',
      addRegion: 'Add region',
      country: 'Country',
      state: 'State',
      city: 'City',
      modal: {
        newRegion: 'New region'
      }
    },
    topics: {
      topic: 'Topic',
      category: 'Category',
      subcategory: 'Subcategory',
      newsCount: 'News count',
      addTopic: 'Add topic',
      allRegions: 'All regions',
      noTopics: 'No topics assigned to this region',
      modal: {
        newTopic: 'New topic'
      }
    }
  },
  'es-US': {
    common: {
      login: 'Ingresar',
      logout: 'Cerrar sesión',
      register: 'Registarse',
      welcome: 'Bienvenido, {{name}}',
      share: 'Compartir',
      userAccount: 'Cuenta de usuario',
      save: 'Guardar',
      cancel: 'Cancelar',
      send: 'Enviar',
      all: 'Todas'
    },
    validations: {
      required: 'Debes ingresar un valor',
      email: 'Debes ingresar un correo válido'
    },
    app: {
      whatIs: 'Qué es',
      editors: 'Editores',
      privacyPolicy: 'Políticas de Privacidad',
      termsOfService: 'Condiciones de Uso'
    },
    loginForm: {
      username: 'Nombre de usuario',
      password: 'Contraseña',
      enterUsername: 'Ingresa tu nombre de usuario',
      enterPassword: 'Ingresa tu contraseña',
      enterCredentials: 'Ingresa tus credenciales'
    },
    signupForm: {
      business: 'Marca o empresa',
      phone: 'Teléfono',
      email: 'Email',
      web: 'Sitio web',
      country: 'País',
      category: 'Categoría'
    },
    home: {
      motto: 'Las Noticias que quieres del lugar que te importa'
    },
    timeline: {
      publishTitle: 'Se parte de Sqoops',
      publishDesc: 'Si eres un Editor y quieres miles de visitas a tus noticias, regístrate ahora'
    },
    editor: {
      mostViewed: 'Las más vistas',
      todaySqoops: `Sqoops de hoy`,
      todayNews: `Noticias de hoy`,
      followers: 'Seguidores',
      followEditor: 'Seguir Editor',
      unfollowEditor: 'Dejar de seguir Editor',
      blockEditor: 'Bloquear Editor'
    },
    account: {
      profile: 'Perfil',
      regions: 'Regiones',
      topics: 'Tópicos',
      languages: 'Idiomas',
      preferences: 'Preferencias',
      blockedEditors: 'Editores bloqueados',
      favoriteEditors: 'Editores favoritos',
      savedArticles: 'Noticias guardadas'
    },
    profile: {
      username: 'Nombre de usuario',
      name: 'Nombre',
      email: 'Correo',
      enterUsername: 'Ingresa tu nombre de usuario',
      enterName: 'Ingresa tu nombre',
      enterEmail: 'Ingresa tu correo'
    },
    regions: {
      region: 'Región',
      showRegionalNews: 'Mostrar noticias regionales',
      newsCount: 'Conteo de noticias',
      addRegion: 'Agregar región',
      country: 'País',
      state: 'Estado',
      city: 'Ciudad',
      modal: {
        newRegion: 'Nueva región'
      }
    },
    topics: {
      topic: 'Tópico',
      category: 'Categoría',
      subcategory: 'Subcategoría',
      newsCount: 'Conteo de noticias',
      addTopic: 'Agregar tópico',
      allRegions: 'Todas las regiones',
      noTopics: 'No hay tópicos asignados a esta región',
      modal: {
        newTopic: 'Nuevo tópico'
      }
    }
  }
}

export function getTranslation(): any {
  const lang = getLanguage()
  return translations[lang]
}
