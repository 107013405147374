import { Category } from './category'
import {Globals} from '../classes/globals'

interface ISubcategory {
  id: number
  name: string
  category: Category
}

export class Subcategory extends Globals {
  id: number
  name: string
  category: Category

  constructor(subcategory: ISubcategory) {
    super()
    Object.assign(this, subcategory)
  }

  static all(category: Category, name: string): Subcategory {
    return new Subcategory({
      id: -1,
      name,
      category
    })
  }
}
