// tslint:disable:max-line-length
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ListComponent } from './components/list/list.component'
import { ScrollingModule } from '@angular/cdk-experimental/scrolling'
import { TimelineComponent } from './pages/timeline/timeline.component'
import { FrameworkComponent } from './components/framework/framework.component'
import { FrameworkRoutingModule } from './framework-routing.module'
import { SharedModule } from '../_shared/shared.module'
import { NewsComponent } from './components/news/news.component'
import { EditorComponent } from './pages/editor/editor.component'
import { AccountComponent } from './pages/account/account.component'
import { AccountProfileComponent } from './pages/account/components/account-profile/account-profile.component'
import { AccountRegionComponent } from './pages/account/components/account-region/account-region.component'
import { AccountTopicComponent } from './pages/account/components/account-topic/account-topic.component'
import { AccountRegionModalComponent } from './pages/account/components/account-region-modal/account-region-modal.component'
import { AccountTopicModalComponent } from './pages/account/components/account-topic-modal/account-topic-modal.component'
// tslint:enable:max-line-length

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ScrollingModule,
    FrameworkRoutingModule
  ],
  declarations: [
    ListComponent,
    TimelineComponent,
    FrameworkComponent,
    NewsComponent,
    EditorComponent,
    AccountComponent,
    AccountProfileComponent,
    AccountRegionComponent,
    AccountTopicComponent,
    AccountRegionModalComponent,
    AccountTopicModalComponent
  ],
  exports: [
    ListComponent
  ],
  entryComponents: [AccountRegionModalComponent, AccountTopicModalComponent]
})
export class FrameworkModule { }
