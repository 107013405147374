/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-buttons.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./form-buttons.component";
var styles_FormButtonsComponent = [i0.styles];
var RenderType_FormButtonsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormButtonsComponent, data: {} });
export { RenderType_FormButtonsComponent as RenderType_FormButtonsComponent };
export function View_FormButtonsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-right pb-4"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_FormButtonsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-buttons", [], null, null, null, View_FormButtonsComponent_0, RenderType_FormButtonsComponent)), i1.ɵdid(1, 114688, null, 0, i2.FormButtonsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormButtonsComponentNgFactory = i1.ɵccf("app-form-buttons", i2.FormButtonsComponent, View_FormButtonsComponent_Host_0, {}, {}, ["*"]);
export { FormButtonsComponentNgFactory as FormButtonsComponentNgFactory };
