import { throwError, Observable, Subscription } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { Injectable, OnDestroy } from '@angular/core'
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor, OnDestroy {
  private error: HttpErrorResponse
  private request: HttpRequest<any>
  private mySubscriptions: Subscription[] = []

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        error => {
          if (error instanceof HttpErrorResponse) {
            this.error = error

            if (error.status === 0 && error.statusText === 'Unknown Error') {
              // const url = error && error.error && error.error.target && error.error.target.__zone_symbol__xhrURL
              return void 0
            }

            this.logTrace(error)

            this.request = req

            switch (error.status) {
              case 400:
                this.handleBadRequest()
                break
              case 401:
                const message = error && error.error && error.error.exp ? error.error.exp : ''
                this.handleUnauthorized(message)
                break
              case 404:
                this.handleNotFound()
                break
              case 403:
                this.handleForbidden()
                break
              case 500:
                this.handleServerError()
                break
              case 503:
                this.handleTimeout()
                break
              default:
                this.showError('Sorry, the request failed. Please try again in a moment')
            }
          }
          return throwError(error)
        }
      ),
      map((event: HttpEvent<any>) => {
        this.logTrace(event as HttpResponse<any>)
        return event
      })
    )
  }

  private handleBadRequest(): void {
    this.error.error !== null ? this.showError() : this.showError('BAD REQUEST !!! Please try again in a moment')
  }

  private handleUnauthorized(message: string): void {
    // this case may occur if the cookie was deleted
    // check whether account is still in local storage
    // Leaving this until we are sure we are handling this correctly
    /*this.mySubscriptions.push(
      this.accountService.getAccountFromStore().subscribe(account => {
        if (account) {
          // if the token expired, logout the user
          if (message.includes('token expired')) {
            // this.accountService.logout(true)
          }
        } else {
          // else redirect to /bye
          this.accountService.logout(true)
        }
      })
    )*/
  }

  private handleForbidden(): void {
    // this.error.error !== null ? this.showError() : this.showError('PERMISSION DENIED !!! Please contact your admin')
  }

  private handleNotFound(): void {
    /*this.error.error !== null
      ? this.showError(this.error.error.message)
      : this.showError('RESOURCE NOT FOUND !!! Please try again in a moment')*/
  }

  private handleServerError(): void {
    this.error.error !== null ? this.showError() : this.showError('SERVER ERROR !!! Please try again in a moment')
  }

  private handleTimeout(): void {
    this.error.error !== null ? this.showError() : this.showError('SERVER UNAVAILABLE !!! Please try again in a moment')
  }

  /**
   * Shows the error using the toast component
   * @param {string} message
   */
  private showError(message?: string): void {
    if (!this.excludeErrors()) {
      if (!message) {
        // const errorMessage = ErrorMessage.getErrorMessage(this.error)

        // this.toastService.show(errorMessage.description, { title: errorMessage.title })
      } else {
        // this.toastService.show(message)
      }
    }
  }

  /**
   * Determines if the error should be shown according to some endpoints excluded
   * @return {boolean}
   */
  private excludeErrors(): boolean {
    const excludeUrls = ['intelligence/intent', 'me/accessentity']

    return excludeUrls.some(excludeUrl => this.request.url.includes(excludeUrl))
  }

  ngOnDestroy() {
    this.mySubscriptions.map(s => s.unsubscribe())
  }

  /**
   * Logs the trace id of the response
   * @param {HttpResponse<any> | HttpErrorResponse} event
   */
  private logTrace(event: HttpResponse<any> | HttpErrorResponse): void {
    if (event.headers && event.headers instanceof HttpHeaders) {
      // this.appService.logTrace(event.headers.get('trace-id'), event instanceof HttpErrorResponse)
    }
  }
}
