var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Globals } from '../classes/globals';
var Subcategory = /** @class */ (function (_super) {
    __extends(Subcategory, _super);
    function Subcategory(subcategory) {
        var _this = _super.call(this) || this;
        Object.assign(_this, subcategory);
        return _this;
    }
    Subcategory.all = function (category, name) {
        return new Subcategory({
            id: -1,
            name: name,
            category: category
        });
    };
    return Subcategory;
}(Globals));
export { Subcategory };
