import { ApiClient } from '../api-client/ApiClient';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Category } from '../../models/category';
import { Subcategory } from '../../models/subcategory';
import { UserRegion } from '../../models/user-region';
import * as i0 from "@angular/core";
import * as i1 from "../api-client/ApiClient";
var TopicService = /** @class */ (function () {
    function TopicService(apiClient) {
        this.apiClient = apiClient;
        this.CATEGORY = 726;
        this.SUBCATEGORY = 179;
        this.REGION = 858;
        this.url = environment.apiEndpoint + "topic/";
    }
    TopicService.prototype.getCategories = function () {
        var params = new HttpParams();
        params = params.append('topic_type', this.CATEGORY.toString());
        return this.apiClient.get(this.url + "list-topic", { params: params }).pipe(map(function (categories) { return categories.map(function (category) { return new Category(category); }); }));
    };
    TopicService.prototype.getSubcategories = function (categoryId) {
        var params = new HttpParams();
        params = params.append('topic_type', this.SUBCATEGORY.toString());
        params = params.append('topic_id', categoryId.toString());
        return this.apiClient.get(this.url + "list-topic", { params: params }).pipe(map(function (subcategories) { return subcategories.map(function (subcategory) { return new Subcategory(subcategory); }); }));
    };
    TopicService.prototype.getUserRegions = function () {
        var params = new HttpParams();
        params = params.append('topic_type', this.REGION.toString());
        return this.apiClient.get(this.url + "list-topic", { params: params }).pipe(map(function (regions) { return regions.map(function (region) { return new UserRegion(region); }); }));
    };
    TopicService.ngInjectableDef = i0.defineInjectable({ factory: function TopicService_Factory() { return new TopicService(i0.inject(i1.ApiClient)); }, token: TopicService, providedIn: "root" });
    return TopicService;
}());
export { TopicService };
