/**
 * Gets the language from queryParam, store and browser.
 */
import { Language } from '../models/translation'

export default function getLanguage(): Language {
  const urlLang = new URL(location.href).searchParams.get('lang') as Language | null
  const browserLang = window.navigator.language
  const accountState = localStorage.getItem('accountState') as string | null
  const appState = localStorage.getItem('appState') as string | null
  let accountLang: Language | undefined
  let appLang: Language | undefined
  let lang: Language = 'en-US'

  if (accountState !== null) {
    try {
      accountLang = JSON.parse(accountState).language
    } catch (err) {
      console.warn(err)
    }
  }

  if (appState !== null) {
    try {
      appLang = JSON.parse(appState).language
    } catch (err) {
      console.warn(err)
    }
  }

  if (urlLang !== null || accountLang !== undefined || appLang !== undefined || browserLang !== undefined) {
    if (
      ['es', 'es-VE', 'es-ES', 'es-US'].includes(urlLang || accountLang || appLang || browserLang)
    ) {
      lang = 'es-US'
    }
  }

  return lang
}
