interface IUserRegion {
  id: number | null
  fullName: string | null
  name?: string
  show_regional_news: number
  hasCity?: boolean
  newsCount?: number
  country_id: number | null
  state_id?: number
  city_id?: number
}

export class UserRegion {
  id: number
  fullName: string
  show_regional_news: number
  hasCity: boolean
  newsCount?: number
  country_id: number
  state_id?: number
  city_id?: number

  constructor(userRegion: IUserRegion) {
    Object.assign(this, userRegion)

    if (!this.fullName) {
      this.fullName = userRegion.name || ''
    }
  }

  get showRegionalNews(): boolean {
    return this.show_regional_news === 1
  }

  static empty(): UserRegion {
    return new UserRegion({
      id: null,
      fullName: null,
      hasCity: false,
      country_id: null,
      show_regional_news: 0
    })
  }
}
