import { Component, OnInit } from '@angular/core'
import { Globals } from './_shared/classes/globals'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { UserService } from './_shared/services/user-service/user.service'
import { filter } from 'rxjs/operators'
import { NewsService } from './_shared/services/news-service/news.service'
import { User } from './_shared/models/user'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends Globals implements OnInit {
  title = 'Sqoops'
  date = new Date()
  isLoggedIn: boolean
  module: string
  newCount = 0
  user: User

  private fullSizeUrls = ['signup-editor']
  isFullSize: boolean

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private newsService: NewsService) {
    super()
  }

  ngOnInit(): void {
    this.getLoginState()
    this.getModule()
    this.getNewCount()
    this.getUser()
  }

  getModule(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      let component = ''

      this.isFullSize = this.fullSizeUrls.some(url => this.router.routerState.snapshot.url.includes(url))

      if (this.route.firstChild && this.route.firstChild) {
        if (this.route.firstChild.firstChild && this.route.firstChild.firstChild.component) {
          component = this.route.firstChild.firstChild.component['name']
        } else if (this.route.firstChild.component) {
          component = this.route.firstChild.component['name']
        }
      }

      this.module = component === 'AuthComponent' ? 'auth' : 'framework'
    })
  }

  getLoginState(): void {
    this.userService.loginState.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn
    })
  }

  logout(): void {
    this.userService.logout().subscribe(() => {
      this.router.navigate(['/home'])
    })
  }

  private getNewCount(): void {
    this.newsService.newNews.pipe(filter(news => news.length > 0)).subscribe(news => {
      this.newCount += news.length
    })

    this.newsService.newsSeen.subscribe(() => {
      if (this.newCount > 0 ) {
        this.newCount--
      }
    })

    this.newsService.newsSeenAll.subscribe(() => {
      this.newCount = 0
    })
  }

  goToPage(): void {
    this.router.navigate([this.module === 'framework' ? '/timeline' : '/home'])
  }

  private getUser(): void {
    if (this.userService.loginState.pipe(
      filter(isLoggedIn => isLoggedIn)
    ).subscribe(() => {
      this.userService.getUser().subscribe(user => this.user = user)
    })) {
    }
  }
}
