<div *ngIf="columns.includes(1) && !loading" class="side-left">
  <ng-content select="[side-left]"></ng-content>
</div>

<div *ngIf="columns.includes(2) && !loading" class="main mx-1">
  <ng-content select="[main]"></ng-content>
</div>

<div *ngIf="columns.includes(3) && !loading" class="side-right">
  <ng-content select="[side-right]"></ng-content>

  <div class="footer px-3">
    <a href="#">{{ translation.app.privacyPolicy }}</a> | <a href="#">{{ translation.app.termsOfService }}</a> <br>
    <span>{{ date | date:'yyyy' }} &copy; {{ title }}</span>
  </div>
</div>

<div *ngIf="loading" class="loader text-center m-5">
  <img src="../../../../assets/images/asterisk.png" />
</div>
