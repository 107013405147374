import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TimeAgoPipe } from './pipes/time-ago.pipe'
import { FormComponent } from './components/form/form.component'
import { FormSectionComponent } from './components/form-section/form-section.component'
import { FormButtonsComponent } from './components/form-buttons/form-buttons.component'
import { ButtonComponent } from './components/button/button.component'
import { MatButtonModule } from '@angular/material/button'
import { ColumnLayoutComponent } from './components/column-layout/column-layout.component'
import { CardComponent } from './components/card/card.component'
import { EditorAvatarComponent } from './components/editor-avatar/editor-avatar.component'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import {
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule, MatSelectModule,
  MatSlideToggleModule,
  MatTableModule
} from '@angular/material'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FilterPipe } from './pipes/filter.pipe'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSelectModule
  ],
  declarations: [
    TimeAgoPipe,
    FilterPipe,
    FormComponent,
    FormSectionComponent,
    FormButtonsComponent,
    ButtonComponent,
    ColumnLayoutComponent,
    CardComponent,
    EditorAvatarComponent
  ],
  exports: [
    TimeAgoPipe,
    FilterPipe,
    FormComponent,
    FormSectionComponent,
    FormButtonsComponent,
    ButtonComponent,
    ColumnLayoutComponent,
    CardComponent,
    EditorAvatarComponent,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSelectModule
  ],
  providers: [TimeAgoPipe, FilterPipe]
})
export class SharedModule { }
