import { Injectable } from '@angular/core'
import { environment } from '../../../../environments/environment'
import { ApiClient } from '../api-client/ApiClient'
import { Observable } from 'rxjs/internal/Observable'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { Editor } from '../../models/editor'

@Injectable({
  providedIn: 'root'
})
export class EditorService {
  private readonly url: string

  constructor(private apiClient: ApiClient) {
    this.url = `${environment.apiEndpoint}editor/`
  }

  /**
   * Gets the editor
   * @param {number} editorId
   * @return {Observable<Editor>}
   */
  getEditor(editorId: number): Observable<Editor> {
    let params = new HttpParams()
    params = params.append('id', editorId.toString())

    return this.apiClient.get<Editor>(`${this.url}profile`, { params }).pipe(
      map(editor => {
        return new Editor(editor)
      })
    )
  }
}
