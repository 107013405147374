import { Routes } from '@angular/router';
import { FrameworkComponent } from './components/framework/framework.component';
import { TimelineComponent } from './pages/timeline/timeline.component';
import { FrameworkGuard } from './framework-guard.service';
import { ListComponent } from './components/list/list.component';
import { EditorComponent } from './pages/editor/editor.component';
import { AccountComponent } from './pages/account/account.component';
var ɵ0 = { settings: 'region' }, ɵ1 = { settings: 'topic' }, ɵ2 = { settings: 'profile' };
var frameworkRoutes = [
    {
        path: '',
        component: FrameworkComponent,
        children: [
            {
                path: 'timeline',
                redirectTo: '',
                pathMatch: 'full'
            },
            {
                path: '',
                component: TimelineComponent,
                canActivate: [FrameworkGuard]
            },
            {
                path: 'editor/:id',
                component: EditorComponent,
                canActivate: [FrameworkGuard]
            },
            {
                path: 'list',
                component: ListComponent,
                canActivate: [FrameworkGuard]
            },
            {
                path: 'account',
                canActivate: [FrameworkGuard],
                children: [
                    {
                        path: '',
                        redirectTo: 'profile',
                        pathMatch: 'full'
                    },
                    {
                        path: 'region',
                        component: AccountComponent,
                        data: ɵ0
                    },
                    {
                        path: 'topic',
                        component: AccountComponent,
                        data: ɵ1
                    },
                    {
                        path: 'profile',
                        component: AccountComponent,
                        data: ɵ2
                    }
                ]
            }
        ]
    }
];
var FrameworkRoutingModule = /** @class */ (function () {
    function FrameworkRoutingModule() {
    }
    return FrameworkRoutingModule;
}());
export { FrameworkRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
