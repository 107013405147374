/**
 * Replace parameters in a message, for example: `replace('-{{x}}-', { x: '1' })` => `'-1-'`.
 * This function should be preferred to `string.replace(/{{x}}/, '1')` since it throws an error
 * if the value cannot be replaced (or silently logs an error, if this happens in production).
 */
export default function replace(msg: string, params: { [x: string]: string | number }): string {
  if (msg) {
    for (const [key, val] of Object.entries(params)) {
      const regExp = new RegExp(`{{${key}}}`, 'g')
      const newMsg = msg.replace(regExp, val + '')
      if (newMsg === msg) {
        const errMsg = `Failed to replace parameter '${key}' with value '${val}' in message '${msg}'`
        console.log(errMsg)
      } else {
        msg = newMsg
      }
    }
  }

  return msg
}
