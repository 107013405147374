import { environment } from '../../../environments/environment'

interface IUser {
  id: number
  username: string
  name: string
  auth_key: string
  picture: string
  email: string
}

export class User {
  id: number
  username: string
  name: string
  auth_key: string
  picture: string
  email: string

  constructor(user: IUser) {
    Object.assign(this, user)
  }

  get pictureUrl(): string {
    return environment.apiProtocol + environment.apiHostname + '/uploads/user/picture/' + this.picture
  }
}
