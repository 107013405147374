import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: Object[], prop: string, value: string | number): Object[] {
    return items ? items.filter(item => item[prop] === value) : []
  }

}
