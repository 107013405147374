var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { UserTopic } from '../../../../../_shared/models/user-topic';
import { Subcategory } from '../../../../../_shared/models/subcategory';
import { MatDialogRef, MatSelect, MatSelectChange } from '@angular/material';
import { TopicService } from '../../../../../_shared/services/topic-service/topic.service';
import { Globals } from '../../../../../_shared/classes/globals';
var AccountTopicModalComponent = /** @class */ (function (_super) {
    __extends(AccountTopicModalComponent, _super);
    function AccountTopicModalComponent(dialogRef, data, topicService) {
        var _this = _super.call(this) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.topicService = topicService;
        _this.userTopic = UserTopic.empty();
        _this.subcategories = [];
        return _this;
    }
    AccountTopicModalComponent.prototype.ngOnInit = function () {
    };
    AccountTopicModalComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    AccountTopicModalComponent.prototype.onCategoryChange = function (change) {
        var _this = this;
        this.userTopic.category_id = change.value;
        this.userTopic.subcategory_id = undefined;
        var category = this.data.categories.find(function (cat) { return cat.id === _this.userTopic.category_id; });
        this.topicService.getSubcategories(this.userTopic.category_id)
            .subscribe(function (subcategories) {
            _this.subcategories = subcategories;
            _this.subcategories.unshift(Subcategory.all(category, _this.translation.common.all));
        });
    };
    AccountTopicModalComponent.prototype.onSubcategoryChange = function (change) {
        // All is selected
        if (change.value.includes(-1) && (!this.lastSubCategoriesValue || !this.lastSubCategoriesValue.includes(-1))) {
            this.userTopic.subcategory_id = undefined;
            this.subCategorySelect.writeValue([-1]);
            this.lastSubCategoriesValue = change.value;
            // All is unselected
        }
        else if (this.lastSubCategoriesValue && this.lastSubCategoriesValue.includes(-1)) {
            this.userTopic.subcategory_id = change.value.filter(function (id) { return id !== -1; });
            this.subCategorySelect.writeValue(this.userTopic.subcategory_id);
            this.lastSubCategoriesValue = this.userTopic.subcategory_id;
        }
        else {
            this.userTopic.subcategory_id = change.value;
            this.lastSubCategoriesValue = change.value;
        }
    };
    AccountTopicModalComponent.prototype.onRegionChange = function (change) {
        this.userTopic.user_regionalization_id = change.value;
    };
    return AccountTopicModalComponent;
}(Globals));
export { AccountTopicModalComponent };
