/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editor-avatar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./editor-avatar.component";
import * as i4 from "@angular/router";
var styles_EditorAvatarComponent = [i0.styles];
var RenderType_EditorAvatarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditorAvatarComponent, data: {} });
export { RenderType_EditorAvatarComponent as RenderType_EditorAvatarComponent };
function View_EditorAvatarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.editor.country == null) ? null : _co.editor.country.name); _ck(_v, 1, 0, currVal_0); }); }
function View_EditorAvatarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "editor-wrapper d-inline-block"]], [[2, "clickable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "d-inline-block"]], [[2, "mr-sm-4", null], [2, "mr-sm-1", null], [8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "editor-info d-inline-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h5", [["class", "m-0 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditorAvatarComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "editor-wrapper d-inline-block"; var currVal_2 = _co.size; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_7 = (_co.size === "normal"); _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigate; _ck(_v, 0, 0, currVal_0); var currVal_3 = (_co.size === "normal"); var currVal_4 = (_co.size === "small"); var currVal_5 = _co.editor.logoUrl; _ck(_v, 2, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.editor.name; _ck(_v, 5, 0, currVal_6); }); }
export function View_EditorAvatarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditorAvatarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editor; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EditorAvatarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-editor-avatar", [], null, null, null, View_EditorAvatarComponent_0, RenderType_EditorAvatarComponent)), i1.ɵdid(1, 49152, null, 0, i3.EditorAvatarComponent, [i4.Router], null, null)], null, null); }
var EditorAvatarComponentNgFactory = i1.ɵccf("app-editor-avatar", i3.EditorAvatarComponent, View_EditorAvatarComponent_Host_0, { editor: "editor", navigate: "navigate", size: "size" }, {}, []);
export { EditorAvatarComponentNgFactory as EditorAvatarComponentNgFactory };
