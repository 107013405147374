/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./column-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./column-layout.component";
var styles_ColumnLayoutComponent = [i0.styles];
var RenderType_ColumnLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColumnLayoutComponent, data: {} });
export { RenderType_ColumnLayoutComponent as RenderType_ColumnLayoutComponent };
function View_ColumnLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "side-left"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
function View_ColumnLayoutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "main mx-1"]], null, null, null, null, null)), i1.ɵncd(null, 1)], null, null); }
function View_ColumnLayoutComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "side-right"]], null, null, null, null, null)), i1.ɵncd(null, 2), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "footer px-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", " \u00A9 ", ""])), i1.ɵppd(11, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translation.app.privacyPolicy; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.translation.app.termsOfService; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 0), _co.date, "yyyy")); var currVal_3 = _co.title; _ck(_v, 10, 0, currVal_2, currVal_3); }); }
function View_ColumnLayoutComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loader text-center m-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "../../../../assets/images/asterisk.png"]], null, null, null, null, null))], null, null); }
export function View_ColumnLayoutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColumnLayoutComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColumnLayoutComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColumnLayoutComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColumnLayoutComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.columns.includes(1) && !_co.loading); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.columns.includes(2) && !_co.loading); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.columns.includes(3) && !_co.loading); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.loading; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ColumnLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-column-layout", [], null, null, null, View_ColumnLayoutComponent_0, RenderType_ColumnLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.ColumnLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColumnLayoutComponentNgFactory = i1.ɵccf("app-column-layout", i3.ColumnLayoutComponent, View_ColumnLayoutComponent_Host_0, { columns: "columns", loading: "loading" }, {}, ["[side-left]", "[main]", "[side-right]"]);
export { ColumnLayoutComponentNgFactory as ColumnLayoutComponentNgFactory };
