import { Editor } from './editor'
import { Subcategory } from './subcategory'
import { News } from './news'

interface ISource {
  id: number
  url: string
  editor_id: number
  type_id: number
  subcategory_id: number
  status_id: number
  last_mined: Date
  has_error: boolean
  editor: Editor
  subcategory: Subcategory
  news: News[]
}

export class Source {
  id: number
  url: string
  editor_id: number
  type_id: number
  subcategory_id: number
  status_id: number
  last_mined: Date
  has_error: boolean
  editor: Editor
  subcategory: Subcategory
  news: News[]

  constructor(source: ISource) {
    Object.assign(this, source)
  }
}
