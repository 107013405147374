import {Component, Inject, OnInit, Optional, ViewChild} from '@angular/core'
import { Category } from '../../../../../_shared/models/category'
import { UserTopic } from '../../../../../_shared/models/user-topic'
import { Subcategory } from '../../../../../_shared/models/subcategory'
import {MAT_DIALOG_DATA, MatDialogRef, MatSelect, MatSelectChange} from '@angular/material'
import { TopicService } from '../../../../../_shared/services/topic-service/topic.service'
import { Globals } from '../../../../../_shared/classes/globals'
import { UserRegion } from '../../../../../_shared/models/user-region'

interface ModalData {
  categories: Category[],
  userRegions: UserRegion[]
}

@Component({
  selector: 'app-account-topic-modal',
  templateUrl: './account-topic-modal.component.html',
  styleUrls: ['./account-topic-modal.component.scss']
})
export class AccountTopicModalComponent extends Globals implements OnInit {
  @ViewChild('subCat') subCategorySelect: MatSelect

  userTopic: UserTopic = UserTopic.empty()
  subcategories: Subcategory[] = []
  lastSubCategoriesValue: number[]

  constructor(
    public dialogRef: MatDialogRef<AccountTopicModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private topicService: TopicService) { super() }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close()
  }

  onCategoryChange(change: MatSelectChange): void {
    this.userTopic.category_id = change.value
    this.userTopic.subcategory_id = undefined
    const category = this.data.categories.find(cat => cat.id === this.userTopic.category_id)

    this.topicService.getSubcategories(this.userTopic.category_id)
      .subscribe(subcategories => {
        this.subcategories = subcategories
        this.subcategories.unshift(Subcategory.all(category as Category, this.translation.common.all))
      })
  }

  onSubcategoryChange(change: MatSelectChange): void {
    // All is selected
    if (change.value.includes(-1) && (!this.lastSubCategoriesValue || !this.lastSubCategoriesValue.includes(-1))) {
      this.userTopic.subcategory_id = undefined
      this.subCategorySelect.writeValue([-1])
      this.lastSubCategoriesValue = change.value
        // All is unselected
    } else if (this.lastSubCategoriesValue && this.lastSubCategoriesValue.includes(-1)) {
      this.userTopic.subcategory_id = change.value.filter(id => id !== -1)
      this.subCategorySelect.writeValue(this.userTopic.subcategory_id)
      this.lastSubCategoriesValue = this.userTopic.subcategory_id as number[]
    } else {
      this.userTopic.subcategory_id = change.value
      this.lastSubCategoriesValue = change.value
    }
  }

  onRegionChange(change: MatSelectChange): void {
    this.userTopic.user_regionalization_id = change.value
  }

}
