interface ICountry {
  id: number
  name: string
  code: string
}

export class Country {
  id: number
  name: string
  code: string

  constructor(country: ICountry) {
    Object.assign(this, country)
  }
}
