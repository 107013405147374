import { Editor } from './editor';
import { Source } from './source';
var News = /** @class */ (function () {
    function News(news) {
        Object.assign(this, news);
        if (this.dateISO) {
            this.dateISO = new Date(this.dateISO);
        }
        if (this.editor) {
            this.editor = new Editor(this.editor);
        }
        if (this.source) {
            this.source = new Source(this.source);
        }
        if (news.seen === null || news.seen === undefined) {
            news.seen = true;
            this.seen = true;
        }
    }
    Object.defineProperty(News.prototype, "hasBigImage", {
        get: function () {
            return !!this.imageUrl && this.image_width >= 400 && this.image_height >= 200;
        },
        enumerable: true,
        configurable: true
    });
    return News;
}());
export { News };
