import { Component, Inject, OnInit, Optional } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatSelectChange, MatSlideToggleChange } from '@angular/material'
import { UserRegion } from '../../../../../_shared/models/user-region'
import { Globals } from '../../../../../_shared/classes/globals'
import { Country } from '../../../../../_shared/models/country'
import { State } from '../../../../../_shared/models/state'
import { City } from '../../../../../_shared/models/city'
import { RegionService } from '../../../../../_shared/services/region-service/region.service'

interface ModalData {
  countries: Country[]
}

@Component({
  selector: 'app-account-region-modal',
  templateUrl: './account-region-modal.component.html',
  styleUrls: ['./account-region-modal.component.scss']
})
export class AccountRegionModalComponent extends Globals implements OnInit {
  userRegion: UserRegion = UserRegion.empty()
  states: State[] = []
  cities: City[] = []

  constructor(
    public dialogRef: MatDialogRef<AccountRegionModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private regionService: RegionService) { super() }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close()
  }

  onCountryChange(change: MatSelectChange): void {
    this.userRegion.country_id = change.value
    this.userRegion.state_id = undefined
    this.userRegion.city_id = undefined

    this.regionService.getStates(this.userRegion.country_id).subscribe(states => this.states = states)
  }

  onStateChange(change: MatSelectChange): void {
    this.userRegion.state_id = change.value
    this.userRegion.city_id = undefined

    this.regionService.getCities(this.userRegion.state_id as number).subscribe(cities => this.cities = cities)
  }

  onCityChange(change: MatSelectChange): void {
    this.userRegion.city_id = change.value
  }

  onShowRegionalNews(toggle: MatSlideToggleChange): void {
    this.userRegion.show_regional_news = toggle.checked ? 1 : 0
  }
}
