import { Component, OnDestroy, OnInit } from '@angular/core'
import { NewsService } from '../../../_shared/services/news-service/news.service'
import { News } from '../../../_shared/models/news'
import { debounceTime, filter } from 'rxjs/operators'
import { fromEvent } from 'rxjs/internal/observable/fromEvent'
import { ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs/internal/Subscription'
import { EditorService } from '../../../_shared/services/editor-service/editor.service'
import { Editor } from '../../../_shared/models/editor'
import { Globals } from '../../../_shared/classes/globals'

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent extends Globals implements OnInit, OnDestroy {

  editorId: number
  editor: Editor
  news: News[]
  orderedNews: News[]
  loading = true

  subscriptions: Subscription[] = []

  constructor(
    private route: ActivatedRoute,
    private newsService: NewsService,
    private editorService: EditorService) { super() }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.editorId = params['id']
      this.getNews()
      this.getOrderedNews()
      this.getEditor()
    })
  }

  ngOnDestroy() {
    this.subscriptions.filter(s => !s.closed).forEach(s => s.unsubscribe())
    this.newsService.pollingCancelled.next(true)
  }

  getNews(): void {
    this.loading = true
    this.subscriptions.push(
      this.newsService.getNews(this.editorId).subscribe(news => {
        this.news = news
        this.loading = false
        // this.onScroll()
        // this.pollNews()
      })
    )
  }

  getOrderedNews(): void {
    this.loading = true
    this.subscriptions.push(
      this.newsService.getOrderedNews(this.editorId, 'view').subscribe(news => {
        this.orderedNews = news
      })
    )
  }

  getEditor(): void {
    this.loading = true
    this.subscriptions.push(
      this.editorService.getEditor(this.editorId).subscribe(editor => {
        this.editor = editor
      })
    )
  }

  onScroll(): void {
    this.subscriptions.push(
      fromEvent(document, 'scroll').pipe(
        debounceTime(300),
        filter(() => !this.loading)
      ).subscribe((event: Event) => {
        if (event.target) {
          const target = event.target as HTMLDocument

          if (target.scrollingElement) {
            const element = target.scrollingElement as HTMLElement
            const height = element.scrollHeight
            const pos = element.scrollTop + element.offsetHeight

            if (pos >= height - 2000) {
              this.loading = true
              this.newsService.getMoreNews(this.news[this.news.length - 1].id).subscribe(news => {
                this.news.push(...news)
                this.loading = false
              })
            }
          }
        }
      })
    )
  }

  pollNews(): void {
    this.subscriptions.push(
      this.newsService.pollNews().subscribe(news => {
        this.news.unshift(...news)
      })
    )
  }

}
