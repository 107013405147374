<h1 mat-dialog-title>{{ translation.topics.modal.newTopic }}</h1>
<div mat-dialog-content>
  <form>
    <app-form-section>
      <mat-form-field class="d-block">
        <mat-select [placeholder]="translation.topics.category" (selectionChange)="onCategoryChange($event)">
          <mat-option *ngFor="let category of data.categories" [value]="category.id">
            {{category.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="d-block">
        <mat-select #subCat [placeholder]="translation.topics.subcategory" [multiple]="true" (selectionChange)="onSubcategoryChange($event)">
          <mat-option *ngFor="let subcategory of subcategories" [value]="subcategory.id">
            {{subcategory.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="d-block">
        <mat-select [placeholder]="translation.regions.region" (selectionChange)="onRegionChange($event)">
          <mat-option [value]="null"></mat-option>
          <mat-option *ngFor="let region of data.userRegions" [value]="region.id">
            {{region.fullName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </app-form-section>
  </form>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-button (click)="close()">{{ translation.common.cancel }}</button>
  <button mat-button [mat-dialog-close]="userTopic" color="accent">{{ translation.common.save }}</button>
</div>
