var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Globals } from '../../../../../_shared/classes/globals';
import { UserService } from '../../../../../_shared/services/user-service/user.service';
import { MatDialog, MatTable } from '@angular/material';
import { AccountRegionModalComponent } from '../account-region-modal/account-region-modal.component';
import { RegionService } from '../../../../../_shared/services/region-service/region.service';
import { filter } from 'rxjs/operators';
var AccountRegionComponent = /** @class */ (function (_super) {
    __extends(AccountRegionComponent, _super);
    function AccountRegionComponent(userService, regionService, dialog) {
        var _this = _super.call(this) || this;
        _this.userService = userService;
        _this.regionService = regionService;
        _this.dialog = dialog;
        _this.isLoading = true;
        _this.displayedColumns = ['fullName', 'showRegionalNews', 'newsCount', 'delete'];
        return _this;
    }
    AccountRegionComponent.prototype.ngOnInit = function () {
        this.getData();
        this.getCountries();
    };
    AccountRegionComponent.prototype.getData = function () {
        var _this = this;
        this.userService.getUserRegions().subscribe(function (userRegions) {
            _this.isLoading = false;
            _this.userRegions = userRegions;
            if (_this.table) {
                _this.table.renderRows();
            }
        });
    };
    AccountRegionComponent.prototype.onShowRegionalNews = function (toggle, region) {
        region.show_regional_news = toggle.checked ? 1 : 0;
        this.userService.updateUserRegion(region).subscribe({
            error: function () { return region.show_regional_news === 1 ? 0 : 1; }
        });
    };
    AccountRegionComponent.prototype.onDeleteRegion = function (deletedRegion) {
        var _this = this;
        var backup = this.userRegions.slice();
        this.userRegions = this.userRegions.filter(function (region) { return region.id !== deletedRegion.id; });
        this.userService.deleteUserRegion(deletedRegion).subscribe({
            error: function () { return _this.userRegions = backup; }
        });
    };
    AccountRegionComponent.prototype.openModal = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AccountRegionModalComponent, {
            width: '400px',
            data: { countries: this.countries }
        });
        dialogRef.afterClosed()
            .pipe(filter(function (newUserRegion) { return !!newUserRegion && newUserRegion.country_id; }))
            .subscribe(function (newUserRegion) {
            _this.userService.sendUserRegion(newUserRegion).subscribe(function () {
                _this.getData();
            });
        });
    };
    AccountRegionComponent.prototype.getCountries = function () {
        var _this = this;
        this.regionService.getCountries().subscribe(function (countries) {
            _this.countries = countries;
        });
    };
    return AccountRegionComponent;
}(Globals));
export { AccountRegionComponent };
