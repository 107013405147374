import { throwError, of, Observable, concat } from 'rxjs'
import { timeoutWith, finalize, retryWhen, mergeMap, delay, take } from 'rxjs/operators'
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { Output, EventEmitter } from '@angular/core'

/**
 * Wrapper of the HttpClient to modify the requests globally
 */
export class ApiClient extends HttpClient {
  private req: Observable<any>
  private url: string
  retries = 3
  delay = 1000
  timeout = 30000

  @Output() responseStart: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() responseEnd: EventEmitter<boolean> = new EventEmitter<boolean>()

  static getQueryParams(queryParams: QueryParams): HttpParams {
    let params = new HttpParams()

    if (queryParams) {
      if (queryParams.page) {
        params = params.append('page', queryParams.page.toString())
      }
      if (queryParams.pageSize) {
        params = params.append('pageSize', queryParams.pageSize.toString())
      }
      // now supports multi clauses: 'Rating asc,Category/Name desc'
      if (queryParams.orderBy) {
        params = params.append('orderby', queryParams.orderBy.replace(/(?:\r\n|\r|\n)/g, ' '))
      }
      if (queryParams.filterBy) {
        params = params.append('filterby', queryParams.filterBy.replace(/(?:\r\n|\r|\n)/g, ' '))
      }
      // set this param to true to get the count of the query
      if (queryParams.count) {
        params = params.append('count', queryParams.count ? 'true' : 'false')
      }
    }

    return params
  }

  /**
   * Sends a GET request to the API
   * @param {string} url
   * @param {Object} options
   * @return {Observable<T>}-
   */
  get<T>(url: string, options?: Object): Observable<T> {
    this.responseStart.emit(true)

    this.req = super.get<T>(url, options).pipe(
      finalize(() => {
        this.responseEnd.emit(true)
      })
    )

    this.url = url

    // Modifiers
    this.timeoutPolicy()
    this.retryPolicy()

    return this.req
  }

  /**
   * Sends a POST request to the API
   * @param {string} url
   * @param body
   * @param {Object} options
   * @return {Observable<T>}
   */
  post<T>(url: string, body: any, options?: Object): Observable<T> {
    this.req = super.post<T>(url, body, options)

    this.url = url

    // Modifiers
    this.timeoutPolicy()
    // this.retryPolicy()

    return this.req
  }

  /**
   * Sends a PUT request to the API
   * @param {string} url
   * @param body
   * @param {Object} options
   * @return {Observable<T>}
   */
  put<T>(url: string, body: any, options?: Object): Observable<T> {
    this.req = super.put<T>(url, body, options)

    this.url = url

    // Modifiers
    this.timeoutPolicy()
    // this.retryPolicy()

    return this.req
  }

  /**
   * Sends a DELETE request to the API
   * @param {string} url
   * @param {Object} options
   * @return {Observable<T>}
   */
  delete<T>(url: string, options?: Object): Observable<T> {
    this.req = super.delete<T>(url, options)

    this.url = url

    // Modifiers
    this.timeoutPolicy()
    // this.retryPolicy()

    return this.req
  }

  /**
   * Defines the retry policy of the requests sent to the API
   */
  retryPolicy(): void {
    this.req = this.req.pipe(
      retryWhen(errors => {
        return concat(
          errors.pipe(
            mergeMap((error: any) => {
              if (error.status === 503) {
                // retry if the server timed out (HTTP ERROR 503)
                return of(error.status).pipe(delay(this.delay)) // Wait before retry
              }
              return throwError(error) // If not timeout, don't do anything
            }),
            take(this.retries) // Set the number of retries
          ),
          of(this.showError(`Sorry, there was an timeout (after ${this.retries} retries)`))
        )
      })
    )
  }

  /**
   * Defines the client timeout policy of the request sent to the API
   */
  timeoutPolicy(): void {
    // Exclude files endpoints for the timeout policy
    if (!this.url.includes('/files')) {
      this.req = this.req.pipe(
        timeoutWith(this.timeout, throwError(new HttpResponse({ status: 503, statusText: 'Client timeout' })))
      )
    }
  }

  showError(message: string): void {
    console.log(message)
  }
}

export interface QueryParams {
  page?: number
  pageSize?: number
  orderBy?: string
  filterBy?: string
  count?: boolean
}
