import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { UserService } from '../../../../../_shared/services/user-service/user.service'
import { Globals } from '../../../../../_shared/classes/globals'
import { User } from '../../../../../_shared/models/user'

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss']
})
export class AccountProfileComponent extends Globals implements OnInit {
  user: User
  profileForm: FormGroup
  isLoading = true
  isSaving = false

  constructor(private formBuilder: FormBuilder, private userService: UserService) { super() }

  ngOnInit() {
    this.getData()
  }

  private buildForm(): void {
    this.profileForm = this.formBuilder.group({
      username: [this.user.username, Validators.required],
      name: [this.user.name, Validators.required],
      email: [this.user.email, Validators.compose([Validators.email, Validators.required])]
    })
  }

  private getData(): void {
    this.userService.getUser().subscribe(user => {
      this.isLoading = false
      this.user = user
      this.buildForm()
    })
  }

  getErrorMessage(field: string) {
    const control = this.profileForm.get(field)

    if (control) {
      return control.hasError('required') ? this.translation.validations.required :
             control.hasError('email') ? this.translation.validations.email : null
    }

    return null
  }

  save(): void {
    if (this.profileForm.valid) {
      this.isSaving = true

      const usernameField = this.profileForm.get('username')
      const nameField = this.profileForm.get('name')
      const emailField = this.profileForm.get('email')

      if (usernameField && nameField && emailField) {
        this.user.username = usernameField.value
        this.user.name = nameField.value
        this.user.email = emailField.value

        this.userService.updateProfile(this.user).subscribe({
          complete: () => this.isSaving = false
        })
      }
    }
  }
}
