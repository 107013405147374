import { Component, ElementRef, Input, OnInit } from '@angular/core'
import { News } from '../../../_shared/models/news'
import { Globals } from '../../../_shared/classes/globals'

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent extends Globals implements OnInit {
  @Input() news: News
  @Input() size = 'normal'

  constructor(private element: ElementRef) { super() }

  ngOnInit() {
  }

  /**
   * Checks if the element is currently visible in a scrollable container
   * @param {HTMLElement} container
   * @param {HTMLElement} element
   * @param {boolean} partial
   * @returns {boolean}
   */
  isVisibleInScroll(container: HTMLElement, partial = false) {
    const element = this.element.nativeElement

    // Get container properties
    const cTop = Math.ceil(container.scrollTop as number)
    const cBottom = cTop + Math.ceil(container.clientHeight as number)

    // Get element properties
    const eTop = element.offsetTop
    const eBottom = eTop + element.clientHeight

    // Check if in view
    const isTotal = eTop >= cTop && eBottom <= cBottom
    const isPartial = partial && ((eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom))

    // Return outcome
    this.news.seen = isTotal || isPartial
    return this.news.seen
  }

}
