<app-column-layout [columns]="[1, 2]" [loading]="loading">
  <div side-left>
    <app-card>
      <h4 class="pl-4 pb-2">{{ translation.common.userAccount }}</h4>

      <div class="menu m-2">
        <button mat-menu-item routerLink="/account/profile" [class.active]="settings === 'profile'">
          <mat-icon>account_circle</mat-icon>
          <span>{{ translation.account.profile }}</span>
          <mat-icon class="active-icon">chevron_right</mat-icon>
        </button>
        <button mat-menu-item routerLink="/account/region" [class.active]="settings === 'region'">
          <mat-icon>public</mat-icon>
          <span>{{ translation.account.regions }}</span>
          <mat-icon class="active-icon">chevron_right</mat-icon>
        </button>
        <button mat-menu-item routerLink="/account/topic" [class.active]="settings === 'topic'">
          <mat-icon>question_answer</mat-icon>
          <span>{{ translation.account.topics }}</span>
          <mat-icon class="active-icon">chevron_right</mat-icon>
        </button>
        <button mat-menu-item routerLink="/account/profile">
          <mat-icon>language</mat-icon>
          <span>{{ translation.account.languages }}</span>
          <mat-icon class="active-icon">chevron_right</mat-icon>
        </button>
        <button mat-menu-item routerLink="/account/profile">
          <mat-icon>settings</mat-icon>
          <span>{{ translation.account.preferences }}</span>
          <mat-icon class="active-icon">chevron_right</mat-icon>
        </button>
        <button mat-menu-item routerLink="/account/profile">
          <mat-icon>favorite</mat-icon>
          <span>{{ translation.account.favoriteEditors }}</span>
          <mat-icon class="active-icon">chevron_right</mat-icon>
        </button>
        <button mat-menu-item routerLink="/account/profile">
          <mat-icon>block</mat-icon>
          <span>{{ translation.account.blockedEditors }}</span>
          <mat-icon class="active-icon">chevron_right</mat-icon>
        </button>
        <button mat-menu-item routerLink="/account/profile">
          <mat-icon>star</mat-icon>
          <span>{{ translation.account.savedArticles }}</span>
          <mat-icon class="active-icon">chevron_right</mat-icon>
        </button>
      </div>
    </app-card>
  </div>

  <div main>
    <app-card>
      <ng-container [ngSwitch]="settings">
        <app-account-profile *ngSwitchCase="'profile'"></app-account-profile>
        <app-account-region *ngSwitchCase="'region'"></app-account-region>
        <app-account-topic *ngSwitchCase="'topic'"></app-account-topic>
      </ng-container>
    </app-card>
  </div>
</app-column-layout>
