import { SocialNetwork } from './socialNetwork'
import { City } from './city'
import { State } from './state'
import { Country } from './country'

interface IEditor {
  id: number
  name: string
  language: string
  logoUrl: string
  url: string
  country_id: number
  state_id: number
  city_id: number
  socialNetworks: SocialNetwork[]
  city: City
  state: State
  country: Country
  sqoopsCount: number
  newsCount: number
  favoritesCount: number
}

export class Editor {
  id: number
  name: string
  language: string
  logoUrl: string
  url: string
  country_id: number
  state_id: number
  city_id: number
  socialNetworks: SocialNetwork[]
  city: City
  state: State
  country: Country
  sqoopsCount: number
  newsCount: number
  favoritesCount: number

  constructor(editor: IEditor) {
    Object.assign(this, editor)
  }
}
