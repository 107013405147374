var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnDestroy, OnInit, QueryList } from '@angular/core';
import { NewsService } from '../../../_shared/services/news-service/news.service';
import { fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { NewsComponent } from '../../components/news/news.component';
import { Globals } from '../../../_shared/classes/globals';
var TimelineComponent = /** @class */ (function (_super) {
    __extends(TimelineComponent, _super);
    function TimelineComponent(newsService) {
        var _this = _super.call(this) || this;
        _this.newsService = newsService;
        _this.loading = false;
        _this.subscriptions = [];
        return _this;
    }
    TimelineComponent.prototype.ngOnInit = function () {
        this.getNews();
        this.checkNewsVisibility();
    };
    TimelineComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.filter(function (s) { return !s.closed; }).forEach(function (s) { return s.unsubscribe(); });
        this.newsService.pollingCancelled.next(true);
    };
    TimelineComponent.prototype.getNews = function () {
        var _this = this;
        this.loading = true;
        this.subscriptions.push(this.newsService.getNews().subscribe(function (news) {
            _this.news = news;
            _this.loading = false;
            _this.onScroll();
            _this.pollNews();
        }));
    };
    TimelineComponent.prototype.onScroll = function () {
        /*of(event).pipe(
          filter(index => index > this.news.length - 20 && !this.loading)
        ).subscribe(() => {
          this.loading = true
          this.newsService.getNews(this.news[this.news.length - 1].id).subscribe(news => {
            this.news.push(...news)
            this.observableData.next(news)
            this.loading = false
          })
        })
      }*/
        var _this = this;
        this.subscriptions.push(fromEvent(document, 'scroll').pipe(debounceTime(300), filter(function () { return !_this.loading; })).subscribe(function (event) {
            if (event.target) {
                var target = event.target;
                if (target.scrollingElement) {
                    var element = target.scrollingElement;
                    var height = element.scrollHeight;
                    var pos = element.scrollTop + element.offsetHeight;
                    if (pos >= height - 2000) {
                        _this.newsService.getMoreNews(_this.news[_this.news.length - 1].id).subscribe(function (news) {
                            var _a;
                            (_a = _this.news).push.apply(_a, news);
                        });
                    }
                }
            }
        }));
    };
    TimelineComponent.prototype.pollNews = function () {
        var _this = this;
        this.subscriptions.push(this.newsService.pollNews().subscribe(function (news) {
            var _a;
            // Prevent auto scroll to the top after prepend
            if (document.scrollingElement && document.scrollingElement.scrollTop === 0) {
                document.scrollingElement.scrollTo(0, 1);
            }
            (_a = _this.news).unshift.apply(_a, news);
        }));
    };
    TimelineComponent.prototype.checkNewsVisibility = function () {
        var _this = this;
        fromEvent(document, 'scroll')
            .pipe(debounceTime(300))
            .subscribe(function (event) {
            if (document.scrollingElement && document.scrollingElement.scrollTop < 25) {
                _this.newsService.newsSeenAll.next();
            }
            else {
                _this.newsComponents.filter(function (component) { return !component.news.seen; }).forEach(function (component) {
                    if (component.isVisibleInScroll(document.scrollingElement)) {
                        _this.newsService.newsSeen.next();
                    }
                });
            }
        });
    };
    return TimelineComponent;
}(Globals));
export { TimelineComponent };
