<div class="hero">
  <h3>{{ translation.account.topics }}</h3>
</div>

<app-button class="float-right mr-2" (click)="openModal()" [disabled]="!categories && !userRegions"><mat-icon>add</mat-icon> {{ translation.topics.addTopic }}</app-button>

<ng-container *ngIf="!isLoading">
  <div class="m-1 mb-4 clear" *ngFor="let region of userRegionsUI">
    <h5>{{ region?.fullName || translation.topics.allRegions }}</h5>

    <ng-container *ngTemplateOutlet="tableTemplate; context: { $implicit: userTopics | filter:'user_regionalization_id':region?.id}"></ng-container>

    <ng-template #tableTemplate let-topics >
      <table mat-table #table *ngIf="topics && topics.length > 0; else noTopics" [dataSource]="topics" class="table-full">

        <!-- Full name Column -->
        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef>{{ translation.topics.topic }}</th>
          <td mat-cell *matCellDef="let topic">{{ topic.fullName }}</td>
        </ng-container>

        <!-- News count Column -->
        <ng-container matColumnDef="newsCount">
          <th mat-header-cell *matHeaderCellDef>{{ translation.topics.newsCount }}</th>
          <td mat-cell *matCellDef="let topic">{{ topic.newsCount }}</td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let topic" (click)="onDeleteTopic(topic)">
            <button mat-icon-button (click)="onDeleteTopic(topic)" color="accent">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <ng-template #noTopics>
        <p class="ml-2 mat-cell"> {{ translation.topics.noTopics }}</p>
      </ng-template>
    </ng-template>
  </div>
</ng-container>

<div *ngIf="isLoading" class="mini-loader text-center my-5">
  <img src="../../../../../../assets/images/asterisk2.png" />
</div>
