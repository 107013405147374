<app-column-layout [columns]="[2, 3]" [loading]="loading">
  <div side-left>
    <app-card theme="dark">
      Test
    </app-card>
  </div>

  <div main>
    <app-card #newsList>
        <app-news *ngFor="let article of news" [news]="article"></app-news>
    </app-card>

    <!--<app-card *ngIf="news" #newsList>
      <cdk-virtual-scroll-viewport class="viewport" [itemSize]="50"  (scrolledIndexChange)="onScroll($event)">
        <app-news *cdkVirtualFor="let article of observableData | async" [news]="article"></app-news>
      </cdk-virtual-scroll-viewport>
    </app-card>-->
  </div>

  <div side-right>
    <app-card class="publish">
      <img src="../../../../assets/images/publish.jpg"/>

      <div class="p-3">
        <h5 class="txt-gray">{{ translation.timeline.publishTitle }}</h5>
        <span class="txt-gray-light">{{ translation.timeline.publishDesc }}</span>
      </div>
    </app-card>
  </div>
</app-column-layout>
