import { Injectable } from '@angular/core'
import { ApiClient } from '../api-client/ApiClient'
import { Observable } from 'rxjs/internal/Observable'
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators'
import { News } from '../../models/news'
import { environment } from '../../../../environments/environment'
import { HttpParams } from '@angular/common/http'
import { Subject } from 'rxjs/internal/Subject'
import { interval } from 'rxjs/internal/observable/interval'
import { of } from 'rxjs/internal/observable/of'

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private readonly url: string
  private readonly editorUrl: string

  newNews = new Subject<News[]>()
  newsSeen = new Subject<void>()
  newsSeenAll = new Subject<void>()
  pollingCancelled = new Subject<boolean>()
  lastId: number

  constructor(private apiClient: ApiClient) {
    this.url = `${environment.apiEndpoint}news/`
    this.editorUrl = `${environment.apiEndpoint}editor/`
  }

  /**
   * Gets the initial list of news
   * @param {number} editorId - To get the news of certain editor
   * @return {Observable<News[]>}
   */
  getNews(editorId?: number): Observable<News[]> {
    let url: string
    let params = new HttpParams()

    if (editorId) {
      params = params.append('editor_id', editorId.toString())
      url = `${this.editorUrl}list`
    } else {
      url = `${this.url}list`
    }

    return this.apiClient.get<News[]>(url, { params }).pipe(
      map((news: News[]) => {
        if (news.length > 0) {
          this.lastId = news[0].id
        }

        return news.map(article => new News(article))
      })
    )
  }

  /**
   * Gets more news (pagination)
   * @param {number} firstId
   * @return {Observable<News[]>}
   */
  getMoreNews(firstId: number): Observable<News[]> {
    let params = new HttpParams()
    params = params.append('first_id', firstId.toString())

    return this.apiClient.get<News[]>(`${this.url}more-articles`, { params }).pipe(
      map((news: News[]) => {
        return news.map(article => new News(article))
      })
    )
  }

  /**
   * Gets latest news (polling)
   * @param {number} lastId
   * @return {Observable<News[]>}
   */
  getNewNews(lastId: number): Observable<News[]> {
    let params = new HttpParams()
    params = params.append('last_id', lastId.toString())

    return this.apiClient.get<News[]>(`${this.url}new-articles`, { params }).pipe(
      map((news: News[]) => {
        // news = [mockNews()]
        news = news.map(article => {
          article.seen = false
          return new News(article)
        })

        if (news.length > 0) {
          this.lastId = news[0].id
          this.newNews.next(news)
        }

        return news
      })
    )
  }

  /**
   * Polls to get new news
   * @return {Observable<News[]>}
   */
  pollNews(): Observable<News[]> {
    return interval(10000).pipe(
      takeUntil(this.pollingCancelled),
      switchMap(_ => {
        const disposableStream$ = this.getNewNews(this.lastId)

        return disposableStream$.pipe(
          filter(news => news && news.length > 0 ),
          catchError(error => {
            return of(error)
          })
        )
      })
    )
  }

  /**
   * Gets random news (for home page)
   * @return {Observable<News[]>}
   */
  getRandom(): Observable<News[]> {
    return this.apiClient.get<News[]>(`${this.url}random`).pipe(
      map((news: News[]) => {
        return news.map(article => new News(article))
      })
    )
  }

  /**
   * Gets the initial list of news
   * @param {number} editorId - To get the news of certain editor
   * @param {string} order - To get the news ordered by
   * @return {Observable<News[]>}
   */
  getOrderedNews(editorId: number, order: string): Observable<News[]> {
    let params = new HttpParams()

    params = params.append('editor_id', editorId.toString())
    params = params.append('order', order)

    return this.apiClient.get<News[]>(`${this.editorUrl}ordered`, { params }).pipe(
      map((news: News[]) => {
        return news.map(article => new News(article))
      })
    )
  }
}
