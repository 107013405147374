import { Injectable } from '@angular/core'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { Observable } from 'rxjs'

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.apiEndpoint)) {
      // Default request options
      const options = {
        withCredentials: true
      }

      let headers = req.headers ? req.headers : new HttpHeaders()

      // If the request is 'cookie' or 'token' (login), set the required header
      if (req.url.endsWith('login')) {
       // headers = headers.set('Content-Type', 'application/x-www-form-urlencoded')
      } else if (localStorage.getItem('sqoops')) {
        headers = headers.set('Authorization', `Bearer ${localStorage.getItem('sqoops')}`)
      }

      // Set default headers
      headers = headers.set('accept', 'application/json')

      options['headers'] = headers

      // Clone the request to add the new options
      const newReq = req.clone(options)

      // Pass on the cloned request instead of the original request.
      return next.handle(newReq)
    } else {
      return next.handle(req)
    }
  }
}
