import { Injectable } from '@angular/core'
import { ApiClient } from '../api-client/ApiClient'
import { User } from '../../models/user'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs/internal/Observable'
import { environment } from '../../../../environments/environment'
import { of } from 'rxjs'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { UserRegion } from '../../models/user-region'
import { UserTopic } from '../../models/user-topic'

@Injectable({ providedIn: 'root' })
export class UserService {

  private readonly url: string

  loginState = new BehaviorSubject<boolean>(!!localStorage.getItem('sqoops'))

  constructor(private apiClient: ApiClient) {
    this.url = `${environment.apiEndpoint}user/`
  }

  login(username: string, password: string): Observable<User> {
    return this.apiClient.post<User>(`${this.url}login`, { username, password }).pipe(
      map(user => {
        user = new User(user)

        localStorage.setItem('sqoops', user.auth_key)
        this.loginState.next(true)

        return user
      })
    )
  }

  logout(): Observable<void> {
    localStorage.clear()
    this.loginState.next(false)

    return of(void 0)
  }

  getUser(): Observable<User> {
    return this.apiClient.get<User>(`${this.url}profile`).pipe(
      map(user => {
        user = new User(user)
        return user
      })
    )
  }

  updateProfile(user: User): Observable<void> {
    return this.apiClient.post<void>(`${this.url}update-profile`, {
      name: user.name,
      username: user.username,
      email: user.email
    })
  }

  getUserRegions(): Observable<UserRegion[]> {
    return this.apiClient.get<UserRegion[]>(`${this.url}list-regions`).pipe(
      map(regions => {
        return regions.map(region => new UserRegion(region))
      })
    )
  }

  sendUserRegion(region: UserRegion): Observable<void> {
    return this.apiClient.post<void>(`${this.url}save-region`, {
      country_id: region.country_id,
      state_id: region.state_id,
      city_id: region.city_id ? [region.city_id] : [],
      show_regional: region.show_regional_news
    })
  }

  updateUserRegion(region: UserRegion): Observable<void> {
    return this.apiClient.post<void>(`${this.url}set-region-show-regional`, {
      region_id: region.id,
      show: region.showRegionalNews ? 'true' : 'false'
    })
  }

  deleteUserRegion(region: UserRegion): Observable<void> {
    return this.apiClient.post<void>(`${this.url}delete-region`, {
      region_id: region.id
    })
  }

  getUserTopics(): Observable<UserTopic[]> {
    return this.apiClient.get<UserTopic[]>(`${this.url}list-topics`).pipe(
      map(topics => {
        return topics.map(topic => new UserTopic(topic))
      })
    )
  }

  sendUserTopic(topic: UserTopic): Observable<void> {
    return this.apiClient.post<void>(`${this.url}save-topic`, {
      topic_id: topic.category_id,
      subtopic_id: Array.isArray(topic.subcategory_id) ? topic.subcategory_id : [topic.subcategory_id],
      region_id: topic.user_regionalization_id
    })
  }

  deleteUserTopic(topic: UserTopic): Observable<void> {
    return this.apiClient.post<void>(`${this.url}delete-topic`, {
      topic_id: topic.id,
      subtopic_id: topic.subcategory_id,
      region_id: topic.user_regionalization_id
    })
  }
}
