var UserRegion = /** @class */ (function () {
    function UserRegion(userRegion) {
        Object.assign(this, userRegion);
        if (!this.fullName) {
            this.fullName = userRegion.name || '';
        }
    }
    Object.defineProperty(UserRegion.prototype, "showRegionalNews", {
        get: function () {
            return this.show_regional_news === 1;
        },
        enumerable: true,
        configurable: true
    });
    UserRegion.empty = function () {
        return new UserRegion({
            id: null,
            fullName: null,
            hasCity: false,
            country_id: null,
            show_regional_news: 0
        });
    };
    return UserRegion;
}());
export { UserRegion };
