import { State } from './state'

interface ICity {
  id: number
  name: string
  state: State
}

export class City {
  id: number
  name: string
  state: State

  constructor(city: ICity) {
    Object.assign(this, city)
  }
}
