import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '../auth/auth-guard.service'
import { FrameworkComponent } from './components/framework/framework.component'
import { TimelineComponent } from './pages/timeline/timeline.component'
import { FrameworkGuard } from './framework-guard.service'
import { ListComponent } from './components/list/list.component'
import { EditorComponent } from './pages/editor/editor.component'
import { AccountComponent } from './pages/account/account.component'

const frameworkRoutes: Routes = [
  {
    path: '',
    component: FrameworkComponent,
    children: [
      {
        path: 'timeline',
        redirectTo: '',
        pathMatch: 'full'
      },
      {
        path: '',
        component: TimelineComponent,
        canActivate: [FrameworkGuard]
      },
      {
        path: 'editor/:id',
        component: EditorComponent,
        canActivate: [FrameworkGuard]
      },
      {
        path: 'list',
        component: ListComponent,
        canActivate: [FrameworkGuard]
      },
      {
        path: 'account',
        canActivate: [FrameworkGuard],
        children: [
          {
            path: '',
            redirectTo: 'profile',
            pathMatch: 'full'
          },
          {
            path: 'region',
            component: AccountComponent,
            data: { settings: 'region' }
          },
          {
            path: 'topic',
            component: AccountComponent,
            data: { settings: 'topic' }
          },
          {
            path: 'profile',
            component: AccountComponent,
            data: { settings: 'profile' }
          }
        ]
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(frameworkRoutes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class FrameworkRoutingModule {}
