import { Component, Input, OnInit } from '@angular/core'
import { Globals } from '../../classes/globals'

@Component({
  selector: 'app-column-layout',
  templateUrl: './column-layout.component.html',
  styleUrls: ['./column-layout.component.scss']
})
export class ColumnLayoutComponent extends Globals implements OnInit {
  @Input() columns = [1, 2, 3]
  @Input() loading = true

  date = new Date()
  title = 'Sqoops'

  constructor() { super() }

  ngOnInit() {
  }

}
