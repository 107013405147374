var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { throwError, of, concat } from 'rxjs';
import { timeoutWith, finalize, retryWhen, mergeMap, delay, take } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
/**
 * Wrapper of the HttpClient to modify the requests globally
 */
var ApiClient = /** @class */ (function (_super) {
    __extends(ApiClient, _super);
    function ApiClient() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.retries = 3;
        _this.delay = 1000;
        _this.timeout = 30000;
        _this.responseStart = new EventEmitter();
        _this.responseEnd = new EventEmitter();
        return _this;
    }
    ApiClient.getQueryParams = function (queryParams) {
        var params = new HttpParams();
        if (queryParams) {
            if (queryParams.page) {
                params = params.append('page', queryParams.page.toString());
            }
            if (queryParams.pageSize) {
                params = params.append('pageSize', queryParams.pageSize.toString());
            }
            // now supports multi clauses: 'Rating asc,Category/Name desc'
            if (queryParams.orderBy) {
                params = params.append('orderby', queryParams.orderBy.replace(/(?:\r\n|\r|\n)/g, ' '));
            }
            if (queryParams.filterBy) {
                params = params.append('filterby', queryParams.filterBy.replace(/(?:\r\n|\r|\n)/g, ' '));
            }
            // set this param to true to get the count of the query
            if (queryParams.count) {
                params = params.append('count', queryParams.count ? 'true' : 'false');
            }
        }
        return params;
    };
    /**
     * Sends a GET request to the API
     * @param {string} url
     * @param {Object} options
     * @return {Observable<T>}-
     */
    ApiClient.prototype.get = function (url, options) {
        var _this = this;
        this.responseStart.emit(true);
        this.req = _super.prototype.get.call(this, url, options).pipe(finalize(function () {
            _this.responseEnd.emit(true);
        }));
        this.url = url;
        // Modifiers
        this.timeoutPolicy();
        this.retryPolicy();
        return this.req;
    };
    /**
     * Sends a POST request to the API
     * @param {string} url
     * @param body
     * @param {Object} options
     * @return {Observable<T>}
     */
    ApiClient.prototype.post = function (url, body, options) {
        this.req = _super.prototype.post.call(this, url, body, options);
        this.url = url;
        // Modifiers
        this.timeoutPolicy();
        // this.retryPolicy()
        return this.req;
    };
    /**
     * Sends a PUT request to the API
     * @param {string} url
     * @param body
     * @param {Object} options
     * @return {Observable<T>}
     */
    ApiClient.prototype.put = function (url, body, options) {
        this.req = _super.prototype.put.call(this, url, body, options);
        this.url = url;
        // Modifiers
        this.timeoutPolicy();
        // this.retryPolicy()
        return this.req;
    };
    /**
     * Sends a DELETE request to the API
     * @param {string} url
     * @param {Object} options
     * @return {Observable<T>}
     */
    ApiClient.prototype.delete = function (url, options) {
        this.req = _super.prototype.delete.call(this, url, options);
        this.url = url;
        // Modifiers
        this.timeoutPolicy();
        // this.retryPolicy()
        return this.req;
    };
    /**
     * Defines the retry policy of the requests sent to the API
     */
    ApiClient.prototype.retryPolicy = function () {
        var _this = this;
        this.req = this.req.pipe(retryWhen(function (errors) {
            return concat(errors.pipe(mergeMap(function (error) {
                if (error.status === 503) {
                    // retry if the server timed out (HTTP ERROR 503)
                    return of(error.status).pipe(delay(_this.delay)); // Wait before retry
                }
                return throwError(error); // If not timeout, don't do anything
            }), take(_this.retries) // Set the number of retries
            ), of(_this.showError("Sorry, there was an timeout (after " + _this.retries + " retries)")));
        }));
    };
    /**
     * Defines the client timeout policy of the request sent to the API
     */
    ApiClient.prototype.timeoutPolicy = function () {
        // Exclude files endpoints for the timeout policy
        if (!this.url.includes('/files')) {
            this.req = this.req.pipe(timeoutWith(this.timeout, throwError(new HttpResponse({ status: 503, statusText: 'Client timeout' }))));
        }
    };
    ApiClient.prototype.showError = function (message) {
        console.log(message);
    };
    return ApiClient;
}(HttpClient));
export { ApiClient };
