import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ScrollingModule } from '@angular/cdk-experimental/scrolling'
import { FrameworkModule } from './framework/framework.module'
import { SharedModule } from './_shared/shared.module'
import { ApiClient } from './_shared/services/api-client/ApiClient'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { ErrorInterceptor } from './_shared/services/interceptors/ErrorInterceptor'
import { RequestInterceptor } from './_shared/services/interceptors/RequestInterceptor'
import { AppRoutingModule } from './app-routing.module'
import { RouterModule } from '@angular/router'

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    ScrollingModule,
    SharedModule,
    FrameworkModule,
    AppRoutingModule
  ],
  providers: [
    ApiClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
