import { ApiClient } from '../api-client/ApiClient';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { News } from '../../models/news';
import { environment } from '../../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs/internal/Subject';
import { interval } from 'rxjs/internal/observable/interval';
import { of } from 'rxjs/internal/observable/of';
import * as i0 from "@angular/core";
import * as i1 from "../api-client/ApiClient";
var NewsService = /** @class */ (function () {
    function NewsService(apiClient) {
        this.apiClient = apiClient;
        this.newNews = new Subject();
        this.newsSeen = new Subject();
        this.newsSeenAll = new Subject();
        this.pollingCancelled = new Subject();
        this.url = environment.apiEndpoint + "news/";
        this.editorUrl = environment.apiEndpoint + "editor/";
    }
    /**
     * Gets the initial list of news
     * @param {number} editorId - To get the news of certain editor
     * @return {Observable<News[]>}
     */
    NewsService.prototype.getNews = function (editorId) {
        var _this = this;
        var url;
        var params = new HttpParams();
        if (editorId) {
            params = params.append('editor_id', editorId.toString());
            url = this.editorUrl + "list";
        }
        else {
            url = this.url + "list";
        }
        return this.apiClient.get(url, { params: params }).pipe(map(function (news) {
            if (news.length > 0) {
                _this.lastId = news[0].id;
            }
            return news.map(function (article) { return new News(article); });
        }));
    };
    /**
     * Gets more news (pagination)
     * @param {number} firstId
     * @return {Observable<News[]>}
     */
    NewsService.prototype.getMoreNews = function (firstId) {
        var params = new HttpParams();
        params = params.append('first_id', firstId.toString());
        return this.apiClient.get(this.url + "more-articles", { params: params }).pipe(map(function (news) {
            return news.map(function (article) { return new News(article); });
        }));
    };
    /**
     * Gets latest news (polling)
     * @param {number} lastId
     * @return {Observable<News[]>}
     */
    NewsService.prototype.getNewNews = function (lastId) {
        var _this = this;
        var params = new HttpParams();
        params = params.append('last_id', lastId.toString());
        return this.apiClient.get(this.url + "new-articles", { params: params }).pipe(map(function (news) {
            // news = [mockNews()]
            news = news.map(function (article) {
                article.seen = false;
                return new News(article);
            });
            if (news.length > 0) {
                _this.lastId = news[0].id;
                _this.newNews.next(news);
            }
            return news;
        }));
    };
    /**
     * Polls to get new news
     * @return {Observable<News[]>}
     */
    NewsService.prototype.pollNews = function () {
        var _this = this;
        return interval(10000).pipe(takeUntil(this.pollingCancelled), switchMap(function (_) {
            var disposableStream$ = _this.getNewNews(_this.lastId);
            return disposableStream$.pipe(filter(function (news) { return news && news.length > 0; }), catchError(function (error) {
                return of(error);
            }));
        }));
    };
    /**
     * Gets random news (for home page)
     * @return {Observable<News[]>}
     */
    NewsService.prototype.getRandom = function () {
        return this.apiClient.get(this.url + "random").pipe(map(function (news) {
            return news.map(function (article) { return new News(article); });
        }));
    };
    /**
     * Gets the initial list of news
     * @param {number} editorId - To get the news of certain editor
     * @param {string} order - To get the news ordered by
     * @return {Observable<News[]>}
     */
    NewsService.prototype.getOrderedNews = function (editorId, order) {
        var params = new HttpParams();
        params = params.append('editor_id', editorId.toString());
        params = params.append('order', order);
        return this.apiClient.get(this.editorUrl + "ordered", { params: params }).pipe(map(function (news) {
            return news.map(function (article) { return new News(article); });
        }));
    };
    NewsService.ngInjectableDef = i0.defineInjectable({ factory: function NewsService_Factory() { return new NewsService(i0.inject(i1.ApiClient)); }, token: NewsService, providedIn: "root" });
    return NewsService;
}());
export { NewsService };
