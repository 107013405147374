var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { MatDialogRef, MatSelectChange, MatSlideToggleChange } from '@angular/material';
import { UserRegion } from '../../../../../_shared/models/user-region';
import { Globals } from '../../../../../_shared/classes/globals';
import { RegionService } from '../../../../../_shared/services/region-service/region.service';
var AccountRegionModalComponent = /** @class */ (function (_super) {
    __extends(AccountRegionModalComponent, _super);
    function AccountRegionModalComponent(dialogRef, data, regionService) {
        var _this = _super.call(this) || this;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.regionService = regionService;
        _this.userRegion = UserRegion.empty();
        _this.states = [];
        _this.cities = [];
        return _this;
    }
    AccountRegionModalComponent.prototype.ngOnInit = function () {
    };
    AccountRegionModalComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    AccountRegionModalComponent.prototype.onCountryChange = function (change) {
        var _this = this;
        this.userRegion.country_id = change.value;
        this.userRegion.state_id = undefined;
        this.userRegion.city_id = undefined;
        this.regionService.getStates(this.userRegion.country_id).subscribe(function (states) { return _this.states = states; });
    };
    AccountRegionModalComponent.prototype.onStateChange = function (change) {
        var _this = this;
        this.userRegion.state_id = change.value;
        this.userRegion.city_id = undefined;
        this.regionService.getCities(this.userRegion.state_id).subscribe(function (cities) { return _this.cities = cities; });
    };
    AccountRegionModalComponent.prototype.onCityChange = function (change) {
        this.userRegion.city_id = change.value;
    };
    AccountRegionModalComponent.prototype.onShowRegionalNews = function (toggle) {
        this.userRegion.show_regional_news = toggle.checked ? 1 : 0;
    };
    return AccountRegionModalComponent;
}(Globals));
export { AccountRegionModalComponent };
