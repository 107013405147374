import { environment } from '../../../../environments/environment';
import { ApiClient } from '../api-client/ApiClient';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Editor } from '../../models/editor';
import * as i0 from "@angular/core";
import * as i1 from "../api-client/ApiClient";
var EditorService = /** @class */ (function () {
    function EditorService(apiClient) {
        this.apiClient = apiClient;
        this.url = environment.apiEndpoint + "editor/";
    }
    /**
     * Gets the editor
     * @param {number} editorId
     * @return {Observable<Editor>}
     */
    EditorService.prototype.getEditor = function (editorId) {
        var params = new HttpParams();
        params = params.append('id', editorId.toString());
        return this.apiClient.get(this.url + "profile", { params: params }).pipe(map(function (editor) {
            return new Editor(editor);
        }));
    };
    EditorService.ngInjectableDef = i0.defineInjectable({ factory: function EditorService_Factory() { return new EditorService(i0.inject(i1.ApiClient)); }, token: EditorService, providedIn: "root" });
    return EditorService;
}());
export { EditorService };
