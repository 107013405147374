var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { News } from '../../../_shared/models/news';
import { Globals } from '../../../_shared/classes/globals';
var NewsComponent = /** @class */ (function (_super) {
    __extends(NewsComponent, _super);
    function NewsComponent(element) {
        var _this = _super.call(this) || this;
        _this.element = element;
        _this.size = 'normal';
        return _this;
    }
    NewsComponent.prototype.ngOnInit = function () {
    };
    /**
     * Checks if the element is currently visible in a scrollable container
     * @param {HTMLElement} container
     * @param {HTMLElement} element
     * @param {boolean} partial
     * @returns {boolean}
     */
    NewsComponent.prototype.isVisibleInScroll = function (container, partial) {
        if (partial === void 0) { partial = false; }
        var element = this.element.nativeElement;
        // Get container properties
        var cTop = Math.ceil(container.scrollTop);
        var cBottom = cTop + Math.ceil(container.clientHeight);
        // Get element properties
        var eTop = element.offsetTop;
        var eBottom = eTop + element.clientHeight;
        // Check if in view
        var isTotal = eTop >= cTop && eBottom <= cBottom;
        var isPartial = partial && ((eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom));
        // Return outcome
        this.news.seen = isTotal || isPartial;
        return this.news.seen;
    };
    return NewsComponent;
}(Globals));
export { NewsComponent };
