var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Globals } from './_shared/classes/globals';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from './_shared/services/user-service/user.service';
import { filter } from 'rxjs/operators';
import { NewsService } from './_shared/services/news-service/news.service';
var AppComponent = /** @class */ (function (_super) {
    __extends(AppComponent, _super);
    function AppComponent(router, route, userService, newsService) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.route = route;
        _this.userService = userService;
        _this.newsService = newsService;
        _this.title = 'Sqoops';
        _this.date = new Date();
        _this.newCount = 0;
        _this.fullSizeUrls = ['signup-editor'];
        return _this;
    }
    AppComponent.prototype.ngOnInit = function () {
        this.getLoginState();
        this.getModule();
        this.getNewCount();
        this.getUser();
    };
    AppComponent.prototype.getModule = function () {
        var _this = this;
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function () {
            var component = '';
            _this.isFullSize = _this.fullSizeUrls.some(function (url) { return _this.router.routerState.snapshot.url.includes(url); });
            if (_this.route.firstChild && _this.route.firstChild) {
                if (_this.route.firstChild.firstChild && _this.route.firstChild.firstChild.component) {
                    component = _this.route.firstChild.firstChild.component['name'];
                }
                else if (_this.route.firstChild.component) {
                    component = _this.route.firstChild.component['name'];
                }
            }
            _this.module = component === 'AuthComponent' ? 'auth' : 'framework';
        });
    };
    AppComponent.prototype.getLoginState = function () {
        var _this = this;
        this.userService.loginState.subscribe(function (isLoggedIn) {
            _this.isLoggedIn = isLoggedIn;
        });
    };
    AppComponent.prototype.logout = function () {
        var _this = this;
        this.userService.logout().subscribe(function () {
            _this.router.navigate(['/home']);
        });
    };
    AppComponent.prototype.getNewCount = function () {
        var _this = this;
        this.newsService.newNews.pipe(filter(function (news) { return news.length > 0; })).subscribe(function (news) {
            _this.newCount += news.length;
        });
        this.newsService.newsSeen.subscribe(function () {
            if (_this.newCount > 0) {
                _this.newCount--;
            }
        });
        this.newsService.newsSeenAll.subscribe(function () {
            _this.newCount = 0;
        });
    };
    AppComponent.prototype.goToPage = function () {
        this.router.navigate([this.module === 'framework' ? '/timeline' : '/home']);
    };
    AppComponent.prototype.getUser = function () {
        var _this = this;
        if (this.userService.loginState.pipe(filter(function (isLoggedIn) { return isLoggedIn; })).subscribe(function () {
            _this.userService.getUser().subscribe(function (user) { return _this.user = user; });
        })) {
        }
    };
    return AppComponent;
}(Globals));
export { AppComponent };
