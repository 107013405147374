import { Injectable } from '@angular/core'
import { ApiClient } from '../api-client/ApiClient'
import { environment } from '../../../../environments/environment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpParams } from '@angular/common/http'
import { Category } from '../../models/category'
import { Subcategory } from '../../models/subcategory'
import { UserRegion } from '../../models/user-region'

@Injectable({
  providedIn: 'root'
})
export class TopicService {
  private readonly url: string
  private CATEGORY = 726
  private SUBCATEGORY = 179
  private REGION = 858

  constructor(private apiClient: ApiClient) {
    this.url = `${environment.apiEndpoint}topic/`
  }

  getCategories(): Observable<Category[]> {
    let params = new HttpParams()
    params = params.append('topic_type', this.CATEGORY.toString())

    return this.apiClient.get<Category[]>(`${this.url}list-topic`, { params }).pipe(
      map(categories => categories.map(category => new Category(category)))
    )
  }

  getSubcategories(categoryId: number): Observable<Subcategory[]> {
    let params = new HttpParams()
    params = params.append('topic_type', this.SUBCATEGORY.toString())
    params = params.append('topic_id', categoryId.toString())

    return this.apiClient.get<Subcategory[]>(`${this.url}list-topic`, { params }).pipe(
      map(subcategories => subcategories.map(subcategory => new Subcategory(subcategory)))
    )
  }

  getUserRegions(): Observable<UserRegion[]> {
    let params = new HttpParams()
    params = params.append('topic_type', this.REGION.toString())

    return this.apiClient.get<UserRegion[]>(`${this.url}list-topic`, { params }).pipe(
      map(regions => regions.map(region => new UserRegion(region)))
    )
  }

}
