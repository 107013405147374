import { Component, Input } from '@angular/core'
import { Editor } from '../../models/editor'
import { Router } from '@angular/router'

@Component({
  selector: 'app-editor-avatar',
  templateUrl: './editor-avatar.component.html',
  styleUrls: ['./editor-avatar.component.scss']
})
export class EditorAvatarComponent {
  @Input() editor: Editor
  @Input() navigate = true
  @Input() size = 'normal'

  constructor(private router: Router) {}

  goToPage(): void {
    this.router.navigate(['/editor', this.editor.id])
  }

}
