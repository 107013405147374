<h1 mat-dialog-title>{{ translation.regions.modal.newRegion }}</h1>
<div mat-dialog-content>
  <form>
    <app-form-section>
      <mat-form-field class="d-block">
        <mat-select [placeholder]="translation.regions.country" (selectionChange)="onCountryChange($event)">
          <mat-option *ngFor="let country of data.countries" [value]="country.id">
            {{country.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="d-block">
        <mat-select [placeholder]="translation.regions.state" (selectionChange)="onStateChange($event)">
          <mat-option [value]="null"></mat-option>
          <mat-option *ngFor="let state of states" [value]="state.id">
            {{state.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="d-block">
        <mat-select [placeholder]="translation.regions.city" (selectionChange)="onCityChange($event)">
          <mat-option [value]="null"></mat-option>
          <mat-option *ngFor="let city of cities" [value]="city.id">
            {{city.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-slide-toggle
        class="d-block"
        *ngIf="!userRegion.city_id"
        (change)="onShowRegionalNews($event)">
        {{ translation.regions.showRegionalNews }}
      </mat-slide-toggle>
    </app-form-section>
  </form>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-button (click)="close()">{{ translation.common.cancel }}</button>
  <button mat-button [mat-dialog-close]="userRegion" color="accent">{{ translation.common.save }}</button>
</div>
