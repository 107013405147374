<div class="hero">
  <h3>{{ translation.account.profile }}</h3>
</div>

<form *ngIf="!isLoading && user" class="px-4">
  <app-form-section>

    <mat-form-field class="d-block">
      <mat-label>{{ translation.profile.username }}</mat-label>
      <input matInput [placeholder]="translation.profile.enterUsername" [formControl]="profileForm.get('username')" required>
      <mat-error *ngIf="profileForm.get('username')?.invalid">{{getErrorMessage('username')}}</mat-error>
    </mat-form-field>

    <mat-form-field class="d-block">
      <mat-label>{{ translation.profile.name }}</mat-label>
      <input matInput [placeholder]="translation.profile.enterName" [formControl]="profileForm.get('name')" required>
      <mat-error *ngIf="profileForm.get('name')?.invalid">{{getErrorMessage('name')}}</mat-error>
    </mat-form-field>

    <mat-form-field class="d-block">
      <mat-label>{{ translation.profile.email }}</mat-label>
      <input matInput [placeholder]="translation.profile.enterEmail" [formControl]="profileForm.get('email')" required>
      <mat-error *ngIf="profileForm.get('email')?.invalid">{{getErrorMessage('email')}}</mat-error>
    </mat-form-field>
  </app-form-section>

  <app-form-buttons>
    <app-button type="submit" color="accent" (clicked)="save()" [isLoading]="isSaving">{{ translation.common.save }}</app-button>
  </app-form-buttons>
</form>

<div *ngIf="isLoading && !user" class="mini-loader text-center my-5">
  <img src="../../../../../../assets/images/asterisk2.png" />
</div>
