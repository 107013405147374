import { ApiClient } from '../api-client/ApiClient';
import { User } from '../../models/user';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { UserRegion } from '../../models/user-region';
import { UserTopic } from '../../models/user-topic';
import * as i0 from "@angular/core";
import * as i1 from "../api-client/ApiClient";
var UserService = /** @class */ (function () {
    function UserService(apiClient) {
        this.apiClient = apiClient;
        this.loginState = new BehaviorSubject(!!localStorage.getItem('sqoops'));
        this.url = environment.apiEndpoint + "user/";
    }
    UserService.prototype.login = function (username, password) {
        var _this = this;
        return this.apiClient.post(this.url + "login", { username: username, password: password }).pipe(map(function (user) {
            user = new User(user);
            localStorage.setItem('sqoops', user.auth_key);
            _this.loginState.next(true);
            return user;
        }));
    };
    UserService.prototype.logout = function () {
        localStorage.clear();
        this.loginState.next(false);
        return of(void 0);
    };
    UserService.prototype.getUser = function () {
        return this.apiClient.get(this.url + "profile").pipe(map(function (user) {
            user = new User(user);
            return user;
        }));
    };
    UserService.prototype.updateProfile = function (user) {
        return this.apiClient.post(this.url + "update-profile", {
            name: user.name,
            username: user.username,
            email: user.email
        });
    };
    UserService.prototype.getUserRegions = function () {
        return this.apiClient.get(this.url + "list-regions").pipe(map(function (regions) {
            return regions.map(function (region) { return new UserRegion(region); });
        }));
    };
    UserService.prototype.sendUserRegion = function (region) {
        return this.apiClient.post(this.url + "save-region", {
            country_id: region.country_id,
            state_id: region.state_id,
            city_id: region.city_id ? [region.city_id] : [],
            show_regional: region.show_regional_news
        });
    };
    UserService.prototype.updateUserRegion = function (region) {
        return this.apiClient.post(this.url + "set-region-show-regional", {
            region_id: region.id,
            show: region.showRegionalNews ? 'true' : 'false'
        });
    };
    UserService.prototype.deleteUserRegion = function (region) {
        return this.apiClient.post(this.url + "delete-region", {
            region_id: region.id
        });
    };
    UserService.prototype.getUserTopics = function () {
        return this.apiClient.get(this.url + "list-topics").pipe(map(function (topics) {
            return topics.map(function (topic) { return new UserTopic(topic); });
        }));
    };
    UserService.prototype.sendUserTopic = function (topic) {
        return this.apiClient.post(this.url + "save-topic", {
            topic_id: topic.category_id,
            subtopic_id: Array.isArray(topic.subcategory_id) ? topic.subcategory_id : [topic.subcategory_id],
            region_id: topic.user_regionalization_id
        });
    };
    UserService.prototype.deleteUserTopic = function (topic) {
        return this.apiClient.post(this.url + "delete-topic", {
            topic_id: topic.id,
            subtopic_id: topic.subcategory_id,
            region_id: topic.user_regionalization_id
        });
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.ApiClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
