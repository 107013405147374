import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  CanLoad,
  Route
} from '@angular/router'

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor() {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return !localStorage.getItem('sqoops')
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state)
  }

  canLoad(route: Route): boolean {
    return !localStorage.getItem('sqoops')
  }
}
