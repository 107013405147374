import { Component, OnInit, ViewChild } from '@angular/core'
import { Globals } from '../../../../../_shared/classes/globals'
import { UserService } from '../../../../../_shared/services/user-service/user.service'
import { MatDialog, MatTable } from '@angular/material'
import { UserRegion } from '../../../../../_shared/models/user-region'
import { UserTopic } from '../../../../../_shared/models/user-topic'
import { Category } from '../../../../../_shared/models/category'
import { TopicService } from '../../../../../_shared/services/topic-service/topic.service'
import { filter } from 'rxjs/operators'
import { AccountTopicModalComponent } from '../account-topic-modal/account-topic-modal.component'

@Component({
  selector: 'app-account-topic',
  templateUrl: './account-topic.component.html',
  styleUrls: ['./account-topic.component.scss']
})
export class AccountTopicComponent extends Globals implements OnInit {
  @ViewChild('table') table: MatTable<UserRegion>

  userTopics: UserTopic[]
  userRegions: UserRegion[]
  categories: Category[]
  isLoading = true
  displayedColumns = ['fullName', 'newsCount', 'delete']

  constructor(
    private userService: UserService,
    private topicService: TopicService,
    private dialog: MatDialog) {
    super()
  }

  ngOnInit() {
    this.getData()
    this.getCategories()
    this.getUserRegions()
  }

  private getData(): void {
    this.userService.getUserTopics().subscribe(userTopics => {
      this.isLoading = false
      this.userTopics = userTopics

      if (this.table) {
        this.table.renderRows()
      }
    })
  }

  private getCategories(): void {
    this.topicService.getCategories().subscribe(categories => {
      this.categories = categories
    })
  }

  private getUserRegions(): void {
    this.topicService.getUserRegions().subscribe(regions => {
      this.userRegions = regions
    })
  }

  onDeleteTopic(deletedTopic: UserTopic): void {
    const backup = [...this.userTopics]
    this.userTopics = this.userTopics.filter(topic => topic.id !== deletedTopic.id)

    this.userService.deleteUserTopic(deletedTopic).subscribe({
      error: () => this.userTopics = backup
    })
  }

  openModal(): void {
    const dialogRef = this.dialog.open(AccountTopicModalComponent, {
      width: '400px',
      data: { categories: this.categories, userRegions: this.userRegions }
    })

    dialogRef.afterClosed()
      .pipe(
        filter(newUserTopic => !!newUserTopic && newUserTopic.category_id)
      )
      .subscribe(newUserTopic => {
        this.userService.sendUserTopic(newUserTopic).subscribe(() => {
          this.getData()
        })
      })
  }

  get userRegionsUI(): UserRegion[] {
    const emptyRegion = UserRegion.empty()
    return [emptyRegion, ...this.userRegions]
  }
}
