<div class="page-wrapper">
  <div class="header">
    <div class="container">
      <div class="float-left">
        <img class="logo mr-3 clickable" src="../assets/images/logo.png" alt="logo" (click)="goToPage()">
        <div *ngIf="module !== 'auth' && newCount" class="rounded-circle new-count">{{ newCount < 100 ? newCount : '+99' }}</div>
        <a class="mx-3" href="#">{{ translation.app.whatIs }}</a>
        <a class="mx-3" routerLink="/signup-editor">{{ translation.app.editors }}</a>
      </div>
      <div class="float-right">
        <a class="mx-3" *ngIf="!isLoggedIn" routerLink="/signup">{{ translation.common.register }}</a>
        <a class="ml-3" *ngIf="!isLoggedIn" routerLink="/login">{{ translation.common.login }}</a>

        <ng-container *ngIf="isLoggedIn && user">
          <div class="user-avatar clickable" [matMenuTriggerFor]="userMenu">
            <span class="mr-1">{{ user.name }}</span>
            <img [src]="user.pictureUrl" alt="user-picture"/>
          </div>

          <mat-menu #userMenu="matMenu" xPosition="before">
            <button mat-menu-item routerLink="/account">
              <mat-icon>account_circle</mat-icon>
              <span>{{ translation.common.userAccount }}</span>
            </button>
            <button mat-menu-item (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>{{ translation.common.logout }}</span>
            </button>
          </mat-menu>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="main container" [class.full]="isFullSize">
    <router-outlet></router-outlet>
  </div>
</div>

<div *ngIf="module === 'auth'" class="footer">
  <div class="container">
    <span>{{ date | date:'yyyy' }} &copy; {{ title }}</span>
    <a class="mx-3" href="#">{{ translation.app.privacyPolicy }}</a>
    <a class="mx-3" href="#">{{ translation.app.termsOfService }}</a>
  </div>
</div>
