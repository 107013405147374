var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Globals } from '../../classes/globals';
var ColumnLayoutComponent = /** @class */ (function (_super) {
    __extends(ColumnLayoutComponent, _super);
    function ColumnLayoutComponent() {
        var _this = _super.call(this) || this;
        _this.columns = [1, 2, 3];
        _this.loading = true;
        _this.date = new Date();
        _this.title = 'Sqoops';
        return _this;
    }
    ColumnLayoutComponent.prototype.ngOnInit = function () {
    };
    return ColumnLayoutComponent;
}(Globals));
export { ColumnLayoutComponent };
