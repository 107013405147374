<app-column-layout [columns]="[1, 2]" [loading]="loading">
  <div side-left>
    <app-card theme="dark" class="editor-info">
      <app-editor-avatar [editor]="editor" class="px-4 mb-4 d-block"></app-editor-avatar>
      <div class="d-flex">
        <div class="text-center">
          <span class="txt-gray-light">{{ translation.editor.todaySqoops }}</span>
          <h2>{{ editor?.sqoopsCount }}</h2>
        </div>
        <div class="text-center">
          <span class="txt-gray-light">{{ translation.editor.todayNews }}</span>
          <h2>{{ editor?.newsCount }}</h2>
        </div>
        <div class="text-center">
          <span class="txt-gray-light">{{ translation.editor.followers }}</span>
          <h2>{{ editor?.favoritesCount }}</h2>
        </div>
      </div>
    </app-card>

    <app-card #orderedNewsList>
      <h5 class="ml-2 mb-4">{{ translation.editor.mostViewed }}</h5>
      <app-news *ngFor="let article of orderedNews" [news]="article" size="small"></app-news>
    </app-card>
  </div>

  <div main>
    <app-card #newsList>
      <app-news *ngFor="let article of news" [news]="article"></app-news>
    </app-card>

    <!--<app-card *ngIf="news" #newsList>
      <cdk-virtual-scroll-viewport class="viewport" [itemSize]="50"  (scrolledIndexChange)="onScroll($event)">
        <app-news *cdkVirtualFor="let article of observableData | async" [news]="article"></app-news>
      </cdk-virtual-scroll-viewport>
    </app-card>-->
  </div>
</app-column-layout>
