import { environment } from '../../../environments/environment';
var User = /** @class */ (function () {
    function User(user) {
        Object.assign(this, user);
    }
    Object.defineProperty(User.prototype, "pictureUrl", {
        get: function () {
            return environment.apiProtocol + environment.apiHostname + '/uploads/user/picture/' + this.picture;
        },
        enumerable: true,
        configurable: true
    });
    return User;
}());
export { User };
