import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-framework',
  templateUrl: './framework.component.html',
  styleUrls: ['./framework.component.scss']
})
export class FrameworkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
