interface ICategory {
  id: number
  name: string
}

export class Category {
  id: number
  name: string

  constructor(category: ICategory) {
    Object.assign(this, category)
  }
}
