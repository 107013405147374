export const environment = {
  production: true,
  apiEndpoint: 'https://www.sqoops.net/api/',
  apiHostname: 'sqoops.net',
  apiProtocol: 'https://',
  imagesUrl: '/assets/images/visuals/',
  clientSecret: 'cS1hcHA6UFNZQktLU2RXTkhuUXNjeHNGTkQyOXU5YW40d1d2SnU=',
  commit: 'prod',
  releaseName: 'Apollo',
  version: '0.1.1001'
}
